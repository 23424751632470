import { AiSettingState } from './types';
import { NavbarSettings } from '../../models/navbar';
import { Module, ActionContext, Action } from 'vuex';
import { RootState, DefaultLoadState, LoadedState, ErrorState, LoadingState } from '../types';
import { getStoreAccessors } from 'vuex-typescript';
import { AiSettingHtml } from '@/services/machine_service';
import { getAisForSignal, getAi, createAiMapping, updateAiMapping, getAiMapping, deleteAiMapping, setAiMappingStatus, updateAiSettings, getAnomaliesForSignal, putSignalObjective, getSignalObjective, PagingQuery, searchAlertsForMachine, getAisForMachine, putAlertMetadata } from '../../services/machine_service/service';
import { SignalAis, AiSettingsLevelValuesResponse, AiSettingsNumberResponse, AiListElement, AiSetting, AiSettings, SignalAnomalies, SignalObjective, AlertMetadata, AlertMetadataUsers } from '../../services/machine_service/types';
import Vue from 'vue';


const defaultState: AiSettingState = {
    // This is a list of all existing AIs
    aiListLoadingState: DefaultLoadState,
    signalId: '',
    machineId: '',
    // This is the overview of all existing Ais for a signal
    signalAisLoadingState: DefaultLoadState,
    // These are the current settings of an AI Mapping for a signal
    aiMappingsLoadingState: DefaultLoadState,
    machineAlerts: DefaultLoadState,
    signalObjectiveLoadingState: DefaultLoadState,
    settingPatches: [],
    editingSignalId: null
};

const actions = {
    loadAiMappings(context: ActionContext<AiSettingState, RootState>, { machineId, signalId }: { machineId: string, signalId: string }) {

        context.commit('aiMappingsLoading', { machineId, signalId });
        if (machineId !== undefined) {
            getAisForMachine(machineId).then(
                (response) => {
                    const genericAiMappings = response.data.result.filter((ai) => ai.isGeneric)
                    context.commit('aiMappingsLoaded', genericAiMappings);
                },
                () => {
                    context.commit('aiMappingsError');
                }
            );
        } else {
            getAisForSignal(signalId).then(
                (response) => {
                    const genericAiMappings = response.data.result.filter((ai) => ai.isGeneric)
                    context.commit('aiMappingsLoaded', genericAiMappings);
                },
                () => {
                    context.commit('aiMappingsError');
                }
            );
        }
    },
    async loadAis(context: ActionContext<AiSettingState, RootState>) {
        context.commit('aiListLoading')
        const response = await getAi().catch(error => { context.commit('aiListError'); throw error });
        context.commit('aiListLoaded', response.data.result);
    },
    async deleteAi(context: ActionContext<AiSettingState, RootState>, mappingId: string) {
        const response = await deleteAiMapping(mappingId).catch(error => { context.commit('aiListError'); throw error });
        context.commit('removeAi', mappingId);

    },
    async createSignalAi(context: ActionContext<AiSettingState, RootState>, { signalId, aiId, name }: { signalId: string, aiId: string, name: string }) {
        const response = await createAiMapping(signalId, aiId, name, context.state.settingPatches)
        context.dispatch('loadAiMappings', { machineId: context.state.machineId, signalId: context.state.signalId });
    },
    async editSignalAi(context: ActionContext<AiSettingState, RootState>, { mappingId }: { mappingId: string }) {
        return await updateAiSettings(mappingId, context.getters.mergedAiSettings)
    },
    async editAiMapping(context: ActionContext<AiSettingState, RootState>, { id, name }: { id: string, name: string }) {
        return await updateAiMapping(id, name).then(response => context.commit('patchMappingName', { id, name }));
    },
    async loadMappingSettings(context: ActionContext<AiSettingState, RootState>, aiMappingId: string) {
        context.commit('signalAiSettingsLoading');
        const response = await getAiMapping(aiMappingId).catch(error => {
            context.commit('signalAiSettingsError');
            throw error
        });
        context.commit('signalAiSettingsLoaded', response.data.configuration);
    },
    async updateMappingSettings(context: ActionContext<AiSettingState, RootState>, { aiMappingId, settings }: { aiMappingId: string, settings: AiSettings }) {
        const response = await updateAiSettings(aiMappingId, settings)
    },
    async startAi(context: ActionContext<AiSettingState, RootState>, id: string) {
        context.commit('setAiStatus', { id, status: 'RUNNING' });
        setAiMappingStatus(id, 'RUNNING').catch(error => { context.commit('aiMappingsError') });
    },
    async stopAi(context: ActionContext<AiSettingState, RootState>, id: string) {
        context.commit('setAiStatus', { id, status: 'STOPPED' });
        setAiMappingStatus(id, 'STOPPED').catch(error => { context.commit('aiMappingsError') });
    },
    async loadMachineAlerts(context: ActionContext<AiSettingState, RootState>, { id, queries }: { id: string, queries: PagingQuery }) {
        context.commit('machineAlertsLoading', id);
        const response = await searchAlertsForMachine(id, queries).then(
            response => {
                context.commit('machineAlertsLoaded', response.data);
                return response;
            },
            error => { context.commit('machineAlertsError') }
        )
        return response;
    },
    async addAlertMetadata(context: ActionContext<AiSettingState, RootState>, { metadata, alertId }: { metadata: AlertMetadataUsers, alertId: string }) {
        const response = await putAlertMetadata(alertId, { ...metadata, assignedUsers: metadata.assignedUsers ? metadata.assignedUsers.map(item => item.id.toString()) : [] });
        context.commit("alertMetadataAdded", { alertId, metadata });
    },
    loadSignalObjective(context: ActionContext<AiSettingState, RootState>, id: string) {
        if (context.state.editingSignalId === id && context.state.signalObjectiveLoadingState.loaded) {
            return;
        }
        context.commit('signalObjectiveLoading', id);
        getSignalObjective(id).then(
            response => {
                context.commit('signalObjectiveLoaded', response.data)
            },
            error => {
                if (error.response && error.response.status === 404) {
                    // Item does not exist, this is fine
                    context.commit('signalObjectiveLoaded', {
                        targetValue: null,
                        lowerTargetValue: null,
                        upperTargetValue: null,
                        thresholds: [],
                        context: "string",
                        isKpi: true,
                    })
                } else {
                    context.commit('signalObjectiveError')
                }

            }
        )
    },
    updateSignalObjective(context: ActionContext<AiSettingState, RootState>, { id, objective }: { id: string, objective: SignalObjective }) {
        context.commit('signalObjectiveLoading', objective)
        putSignalObjective(id, objective).then(
            (response) => {
                context.commit('signalObjectiveLoaded', objective)
            },
            (error) => {
                context.commit('signalObjectiveError', objective)
            }
        );

    }

};

const mutations = {
    aiListLoading(state: AiSettingState) {
        state.aiListLoadingState = LoadingState;
    },
    aiListLoaded(state: AiSettingState, result: AiListElement[]) {
        state.aiListLoadingState = { ...LoadedState, result }
    },
    aiListError(state: AiSettingState) {
        state.aiListLoadingState = ErrorState;
    },
    alertMetadataAdded(state: AiSettingState, {alertId, metadata}: {alertId: string, metadata: AlertMetadataUsers}){
        const alert = state.machineAlerts.result.alerts.find(alert => alert.uuid === alertId);
        alert.resolved = metadata.resolved;
        alert.assignedUsers = metadata.assignedUsers;
    },
    removeAi(state: AiSettingState, id: string) {
        state.signalAisLoadingState.result.splice(state.signalAisLoadingState.result.findIndex(item => item.id === id));
    },
    setAiStatus(state: AiSettingState, { id, status }: { id: string, status: string }) {
        const itemIndex = state.signalAisLoadingState.result.findIndex(item => item.id === id);
        if (itemIndex > -1) {
            Vue.set(state.signalAisLoadingState.result[itemIndex], 'status', status);
        }
    },
    aiMappingsLoading(state: AiSettingState, { signalId, machineId }: { signalId: string, machineId: string }) {
        state.signalAisLoadingState = {...LoadingState, result: state.signalAisLoadingState.result };
        state.signalId = signalId;
        state.machineId = machineId;
    },
    aiMappingsLoaded(state: AiSettingState, payload: SignalAis[]) {
        state.signalAisLoadingState = { ...LoadedState, result: payload };
    },
    aiMappingsError(state: AiSettingState) {
        state.signalAisLoadingState = ErrorState;
    },
    signalAiSettingsLoading(state: AiSettingState) {
        state.aiMappingsLoadingState = LoadingState;
    },
    signalAiSettingsLoaded(state: AiSettingState, result: AiSettings) {
        state.aiMappingsLoadingState = { ...LoadedState, result }
    },
    signalAiSettingsError(state: AiSettingState) {
        state.aiMappingsLoadingState = ErrorState
    },
    /**
     * Add a patch locally, which will be sent to the server when the user clicks save
     */
    addAiSettingPatch(state: AiSettingState, patch: AiSetting) {
        const existingPatchIndex = state.settingPatches.findIndex(item => item.id === patch.id);
        if (existingPatchIndex > -1) {
            state.settingPatches.splice(existingPatchIndex, 1, patch)
        } else {
            state.settingPatches.push(patch);
        }
    },
    removeAiSettingPatch(state: AiSettingState, patch: AiSetting) {
        const existingPatchIndex = state.settingPatches.findIndex(item => item.id === patch.id);
        if (existingPatchIndex > -1) {
            state.settingPatches.splice(existingPatchIndex, 1)
        }
    },
    patchMappingName(state: AiSettingState, patch: { id: string, name: string }) {
        const item = state.signalAisLoadingState.result.find(item => item.id === patch.id);
        if (item) {
            item.name = patch.name;
        }
    },
    clearSettingPatches(state: AiSettingState) {
        state.settingPatches = [];
    },
    clearLoadedSettings(state: AiSettingState) {
        state.aiMappingsLoadingState = DefaultLoadState;
    },
    machineAlertsLoading(state: AiSettingState, id: string) {
        state.machineAlerts = LoadingState;
        state.editingSignalId = id;
    },
    machineAlertsLoaded(state: AiSettingState, result: { alerts: SignalAnomalies[], pagination: Pagination }) {
        state.machineAlerts = { ...LoadedState, result };

    },
    machineAlertsError(state: AiSettingState) {
        state.machineAlerts = ErrorState;
    },

    signalObjectiveLoading(state: AiSettingState, id: string) {
        state.signalObjectiveLoadingState = LoadingState;
        state.editingSignalId = id;
    },
    signalObjectiveLoaded(state: AiSettingState, result: SignalObjective) {
        state.signalObjectiveLoadingState = { ...LoadedState, result };

    },
    signalObjectiveError(state: AiSettingState) {
        state.signalObjectiveLoadingState = ErrorState;
    }
};

const getters = {
    aiList: (state: AiSettingState) => state.aiListLoadingState.result,
    aiListLoadingState: (state: AiSettingState) => state.aiListLoadingState,
    signalAiSettings: (state: AiSettingState) => {
        return state.signalAisLoadingState.result;
    },
    signalObjective: (state: AiSettingState) => state.signalObjectiveLoadingState,
    machineAlerts: (state: AiSettingState) => state.machineAlerts,
    editingSignalId: (state: AiSettingState) => state.editingSignalId,
    // NOTE: these are the settings merged with the current local changes
    mergedAiSettings(state: AiSettingState): AiSettings {
        const currentSettings = state.aiMappingsLoadingState.loaded ? state.aiMappingsLoadingState.result : [] as AiSettings;
        const settingPatchtes = state.settingPatches;
        return [...currentSettings, ...settingPatchtes].reduce((prev: AiSettings, curr: AiSetting) => {
            const existingItemIndex = prev.findIndex((item: any) => item.id === curr.id);
            if (existingItemIndex > -1) {
                prev.splice(existingItemIndex, 1, { ...prev[existingItemIndex], ...curr })
            }
            else {
                prev.push(curr);
            }
            return prev;
        }, [])

    },
    hasAiSettingChanges: (state: AiSettingState) => state.settingPatches.length > 0,
    aiMappingsLoadingState: (state: AiSettingState) => state.aiMappingsLoadingState,
};



export const aiSettings: Module<AiSettingState, RootState> = {
    namespaced: true,
    state: defaultState,
    getters,
    actions,
    mutations,
};



const { commit, read, dispatch } = getStoreAccessors<AiSettingState, RootState>(
    'aiSettings',
);

// Getters
export const signalAiSettings = read(getters.signalAiSettings);
export const aiMappingsLoadingState = read(getters.aiMappingsLoadingState);
export const getMachineAlerts = read(getters.machineAlerts);

// Actions

export const addAlertMetadata = dispatch(actions.addAlertMetadata);
// Mutations
