
















































import Vue from "vue";
import Loading from "@/components/Loading.vue";
import {
  deleteMachineProperty,
  getSignals,
  SignalOverview,
} from "@/services/machine_service";
import { userHasRole } from "@/util/roles";
import { toPercentStr } from "../util/formatting";

export default Vue.extend({
  components: {
    Loading,
  },
  props: {
    machineId: String,
  },
  data() {
    return {
      toPercentStr,
      loading: false,
      loaded: false,
      error: false,
      overview: [] as SignalOverview[],
      search: "",
      anomalyDetectionAi: null,
    };
  },
  computed: {
    userCanEdit(): boolean {
      return userHasRole(["expert", "global_admin", "system_admin"]);
    },
    filteredOverview(): SignalOverview[] {
      return this.overview.filter(
        (item: SignalOverview) =>
          item.name.toLowerCase().includes(this.search.toLowerCase()) ||
          item.machine.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    formatDate(row: any, col: any, value: string) {
      if (!value) {
        return "";
      }
      const index = value.indexOf(".");
      if (index === -1) {
        return value;
      }
      return value.substring(0, index);
    },
    loadData() {
      this.loading = true;
      // NOTE: currently signals api has no machine filter
      getSignals({ machineUuid: this.machineId }).then(
        (response) => {
          this.overview = response.data.signals;
          this.loading = false;
          this.loaded = true;
          this.error = false;
        },
        (error) => {
          this.error = true;
          this.loading = false;
          this.loaded = false;
          this.overview = [];
        }
      );
    },
    rowclick(row: SignalOverview) {
      this.$router.push({
        name: "signalEdit",
        params: { id: row.id, twinId: row.machine.id },
      });
    },
    deleteSignal(signal: SignalOverview) {
      deleteMachineProperty(signal.machine.id, signal.id).then(this.loadData);
    },
  },
  mounted() {
    this.loadData();
  },
});
