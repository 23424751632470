





























import { createMaterial } from "@/services/machine_service";
import {
  DefaultLoadState,
  ErrorState,
  LoadedState,
  LoadingState,
} from "@/store/types";
import Vue from "vue";
import Loading from "@/components/Loading.vue";
export default Vue.extend({
  components: {
    Loading,
  },
  data: () => ({
    material: {
      identifier: "",
      name: "",
      description: "",
      type: "",
    },
    loadingState: DefaultLoadState,
  }),
  computed: {
    isSendButtonActivated(): boolean {
      return (
        this.material.identifier !== "" &&
        this.material.name !== "" &&
        this.material.description !== "" &&
        this.material.type !== ""
      );
    },
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    sendData() {
      this.loadingState = LoadingState;
      createMaterial(this.material).then(
        () => {
          this.$router.push({ name: "materials" });
          this.loadingState = LoadedState;
        },
        (error) => {
          this.loadingState = ErrorState;
        }
      );
    },
  },
});
