import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index';
import i18n from './internationalization';
import axios from 'axios';
import resize from 'vue-resize-directive';

import { AuthInterceptor, AuthErrorInterceptor } from '@/services/interceptors';
// import 'element-ui/lib/theme-chalk/index.css';


import {
  Alert,
  Badge,
  Collapse,
  CollapseItem,
  Loading,
  Message,
  Menu,
  MenuItem,
  MenuItemGroup,
  Input,
  Button,
  Option,
  Select,
  Switch,
  InputNumber,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Tabs,
  TabPane,
  Popover,
  Dialog,
  Tooltip,
  DatePicker,
  Pagination,
  Table,
  TableColumn,
} from 'element-ui';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';

import './assets/theme/index.css';
import 'c3/c3.css';

Vue.config.productionTip = false;

Vue.config.performance = true

axios.interceptors.request.use(AuthInterceptor, (error) => { return; });
axios.interceptors.response.use((response) => {
  return response;
}, AuthErrorInterceptor);

// TODO: check what is actually being used

// configure language
locale.use(lang);

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Collapse);
Vue.use(CollapseItem);


Vue.use(Tabs);
Vue.use(TabPane);

Vue.use(Loading.directive);
Vue.use(Button);
Vue.use(Badge);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Input);

Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);

Vue.use(Dialog);
Vue.use(Alert);
Vue.use(Select);
Vue.use(Option);
Vue.use(Switch);
Vue.use(Popover);
Vue.use(Pagination);
Vue.use(InputNumber);
Vue.use(Tooltip);

Vue.use(DatePicker);

Vue.directive('resize', resize);

Vue.prototype.$message = Message;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
