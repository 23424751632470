



























































































import Vue from "vue";
import Loading from "@/components/Loading.vue";
import EditAnalysis from "@/components/EditAnalysis.vue";
import TargetThresholds from "@/components/TargetThresholds.vue";
import CommentSidebar from "@/components/CommentSidebar.vue";
import SingleSignalChart from "@/components/SingleSignalChart.vue";
import SignalAlerts from "@/components/SignalAlerts.vue";

import {
  createMachineProperty,
  getProperties,
  getTwins,
  updateMachineProperty,
} from "../services/machine_service/service";
import { MachineProperty } from "../services/machine_service/types";
import { mapGetters } from "vuex";
import { setBreadCrumbs } from "@/store/global_variables";

export default Vue.extend({
  components: {
    Loading,
    EditAnalysis,
    TargetThresholds,
    CommentSidebar,
    SingleSignalChart,
    SignalAlerts,
  },
  props: {
    id: { type: String, required: false },
    twinId: { type: String, required: false },
  },
  data() {
    return {
      tab: "signalData",
      types: ["float"],
      error: false,
      loadingMachines: false,
      loadingSignal: false,
      loadingSendData: false,
      machine: "",
      twins: [],
      data: {
        name: "",
        type: "float",
        unit: "",
        isManual: false,
        isTimeSeries: true,
        mappingName: "",
        // THESE ARE HARDCODED PROPERTIES REQUIRED BY THE BACKEND
        aggregation: "avg",
        isIdentifier: false,
        order: 0,
      } as MachineProperty,
    };
  },
  computed: {
    ...mapGetters("aiSettings", ["hasAiSettingChanges"]),
    loading(): boolean {
      return this.loadingMachines || this.loadingSignal || this.loadingSendData;
    },
    hasSignalChanges(): boolean {
      return (
        this.data.name !== "" &&
        this.data.type !== "" &&
        this.data.unit !== "" &&
        this.machine !== "" &&
        this.data.mappingName !== ""
      );
    },
    isSendButtonActivated(): boolean {
      return this.hasSignalChanges || this.hasAiSettingChanges;
    },
    isEdit(): boolean {
      return this.twinId !== undefined && this.id !== undefined;
    },
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    async sendData() {
      if (!this.isSendButtonActivated) {
        return;
      }
      if (this.hasSignalChanges) {
        this.loadingSendData = true;
        if (this.isEdit) {
          updateMachineProperty(this.machine, {
            ...this.data,
            uuid: this.id,
          }).then((_) => {
            this.loadingSendData = false;
            this.$router.push({ name: "signals" });
          });
        } else {
          try {
            const propertyResponse = await createMachineProperty(
              this.machine,
              this.data
            );

            this.loadingSendData = false;
            this.$router.push({ name: "signals" });
          } catch {
            this.loadingSendData = false;
            this.error = true;
          }
        }
      }
    },
  },
  mounted() {
    this.loadingMachines = true;
    getTwins().then(
      (response) => {
        this.loadingMachines = false;
        this.twins = response.data;
        if (this.$route.query.machine) {
          this.machine = this.$route.query.machine as string;
        }
      },
      (error) => {
        this.loadingMachines = false;
        this.error = true;
      }
    );
    if (this.twinId) {
      this.loadingSignal = true;
      getProperties(this.twinId).then((response) => {
        const property = response.data.find((item) => item.uuid === this.id);
        if (property) {
          this.data.name = property.name;
          this.data.type = property.type;
          this.data.unit = property.unit;
          this.data.mappingName = property.mappingName;
          this.machine = this.twinId;
        }
        this.loadingSignal = false;
      });
    }

    setBreadCrumbs(this.$store, [
      {
        name: "machineDetails",
        i18n: true,
        route: { name: "machineDetail", params: { id: this.twinId } },
      },
      {
        name: "sensorManagement",
        i18n: true,
        route: {
          name: "signalEdit",
          params: { id: this.id, twinId: this.twinId },
        },
      },
    ]);
  },
});
