



























































import {
  DefaultLoadState,
  ErrorState,
  LoadedState,
  LoadingState,
} from "@/store/types";
import Vue from "vue";
import { addComment, getComments } from "../services/comment_service/service";
import Loading from "@/components/Loading.vue";
import { formatDate } from "../util";
import { GenericComment } from '@/services/comment_service';
import { UserOverview } from '@/services/user_service';

export default Vue.extend({
  components: {
    Loading,
  },
  props: {
    referenceId: { type: String, required: true },
    referenceType: { type: String, required: true },
    referenceStart: { type: Object, required: false },
    referenceEnd: { type: Object, required: false },
  },
  data: () => ({
    loadingState: DefaultLoadState,
    formatDate,
    message: "",
  }),
  computed: {
    comments(): GenericComment[] {
      return this.loadingState.result;
    },
  },
  methods: {
    formatUser(user: UserOverview) {
        return user.attributes.firstname ? `${user.attributes.firstname} ${user.attributes.lastname}` : user.username;
    },
    sendMessage() {
      const message = this.message;
      this.message = "";
      addComment({
        value: message,
        format: "plain",
        referenceId: this.referenceId,
        referenceType: this.referenceType,
        referenceStart: this.referenceStart,
        referenceEnd: this.referenceEnd,
      }).then((result) => {
        this.loadingState.result.push(result.data);
        Vue.nextTick(() => {
          this.scrollMessagesDown();
        });
      });
    },
    scrollMessagesDown() {
      const container = this.$refs["messageContainer"] as Element;
      if (container) {
        container.scrollTo({
          top: container.scrollHeight,
          behavior: "smooth",
        });
      }
    },
  },
  mounted() {
    this.loadingState = LoadingState;
    getComments({
      referenceId: this.referenceId,
      referenceType: this.referenceType,
      referenceStart: this.referenceStart,
      referenceEnd: this.referenceEnd,
    }).then(
      (response) => {
        this.loadingState = { ...LoadedState, result: response.data.reverse() };
        Vue.nextTick(() => {
          this.scrollMessagesDown();
        });
      },
      (error) => {
        this.loadingState = ErrorState;
      }
    );
  },
});
