// TODO: remove trailing slash
export const authApiRoot = '/auth';
export const apiRoot = '/api';
export const machineApiRoot = '/machineApi';
export const twinsRoot = '/twins';
export const aiRoot = '/machineApi/ai';
export const pipelineRoot = '/pipelines';
export const signalsRoot = '/machineApi/signals'

export const singupKey = 'creche.grosbeak.sauce.myth';

export const version = '0.0.1';

export const DEBUG = true;

