














































import Vue from "vue";
import Loading from "@/components/Loading.vue";
import {
  createDataSource,
  getDataSources,
  removeDataSource,
  updateDataSource,
} from "../services/data_source_service/service";
import { setBreadCrumbs } from "@/store/global_variables";
export default Vue.extend({
  components: {
    Loading,
  },
  props: {
    id: { type: String, required: false },
  },
  data() {
    return {
      error: false,
      loading: false,
      config: {
        username: "",
        password: "",
        address: "",
        topic: "",
      },
      version: "",
      gateway: "mqtt",
    };
  },
  computed: {
    isEdit(): boolean {
      return this.id !== undefined;
    },
    isSendButtonActivated(): boolean {
      return (
        this.config.address !== "" &&
        this.version !== "" &&
        this.gateway !== "" &&
        this.config.address.startsWith("tcp://")
      );
    },
    sanityRationale(): string {
      if (this.config.address === "") {
        return this.$tc("enter_connection_string");
      }
      if (this.version === "") {
        return this.$tc("enter_version");
      }
      if (this.gateway === "") {
        return this.$tc("enter_gateway");
      }
      if (!this.config.address.startsWith("tcp://")) {
        return this.$tc("enter_gateway_tcp");
      }
      return "";
    },
  },
  methods: {
    sendData() {
      if (!this.isSendButtonActivated) {
        return;
      }
      this.loading = true;
      if (this.isEdit) {
        // NOTE: the backend does not support updating, thus we have to delete and then create again
        // https://github.com/inctec/smc-manufacturing-ui/issues/9
        // updateDataSource({
        //   uuid: this.id,
        //   gateway: this.gateway,
        //   version: this.version,
        //   config: JSON.stringify(this.config),
        // }).then((response) => {
        //   this.loading = false;
        //   this.$router.push({ name: "dataSources" });
        // });
        removeDataSource(this.id).then((_) => {
          createDataSource({
            gateway: this.gateway,
            version: this.version,
            config: JSON.stringify(this.config),
          }).then((response) => {
            this.loading = false;
            this.$router.push({ name: "dataSources" });
          });
        });
      } else {
        createDataSource({
          gateway: this.gateway,
          version: this.version,
          config: JSON.stringify(this.config),
        }).then((response) => {
          this.loading = false;
          this.$router.push({ name: "dataSources" });
        });
      }
    },
  },
  mounted() {
    if (this.isEdit) {
      this.loading = true;
      getDataSources().then(
        (response) => {
          const dataSource = response.data.find(
            (item) => item.uuid === this.id
          );
          const config = JSON.parse(dataSource.config);
          this.config = config;
          this.version = dataSource.version;
          this.gateway = dataSource.gateway;
          this.loading = false;
        },
        (_) => {
          this.error = true;
        }
      );
    }

    setBreadCrumbs(this.$store, [
      {
        name: "data_source_overview",
        i18n: true,
        route: { name: "dataSources" },
      },
      {
        name: this.isEdit ? "edit_data_source" : "add_data_source",
        i18n: true,
        route: {
          name: this.isEdit ? "dataSourceEdit" : "dataSourceCreate",
          params: {
            id: this.id,
          },
        },
      },
    ]);
  },
});
