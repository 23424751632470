








































































import {
  getSignalTimeseriesForSignal,
  SignalAnomalies,
  SignalTimeseries,
  Threshold,
} from "@/services/machine_service";
import moment from "moment";
import Vue from "vue";
import SignalChart, { ColorMap } from "@/components/SignalChart.vue";
import Loading from "@/components/Loading.vue";
import { mapGetters } from "vuex";
import { SignalObjective } from "../services/machine_service/types";
import { generateColors } from "@/util/colorGenerator";
import {
  DefaultLoadState,
  ErrorState,
  LoadedState,
  LoadingState,
} from "@/store/types";
import { addComment, GenericComment, getComments } from "@/services/comment_service";

export default Vue.extend({
  components: {
    SignalChart,
    Loading,
  },
  props: {
    id: String,
  },
  data() {
    return {
      loading: false,
      loaded: false,
      error: false,
      loadingDetailData: false,
      loadingStateComments: DefaultLoadState,
      sensors: [] as SignalTimeseries[],
      sensorDetails: [] as SignalTimeseries[],
      anomalies: [] as SignalAnomalies[],
      hideThresholds: [] as number[],
      signalChartWidth: 980,
      signalChartHeight: 300,
      detailChartRange: [],
      overviewChartRange: [
        moment().subtract(1, "days").toISOString(),
        moment().toISOString(),
      ],
    };
  },
  computed: {
    ...mapGetters("aiSettings", ["signalObjective"]),
    objective(): SignalObjective {
      if (!this.signalObjective.loaded) {
        return null;
      }
      return {
        ...(this.signalObjective.result as SignalObjective),
        thresholds: (
          this.signalObjective.result as SignalObjective
        ).thresholds.filter(
          (item, index) => !this.hideThresholds.includes(index)
        ),
      };
    },
    visibleAnomalies(): SignalAnomalies[] {
      // Check if signal is hidden
      return this.anomalies;
    },
    colors(): ColorMap[] {
      const colors = generateColors(
        this.sensorDetails.length + this.anomalies.length
      );

      return [
        ...this.sensorDetails.map((item, index) => ({
          id: item.propertyId,
          color: colors[index],
        })),
      ];
    },
  },
  methods: {
    async reloadData() {
      await this.loadOverview();
      this.loadChartDetailData(this.detailChartRange);
    },
    switchHideThreshold(thresholdIndex: number, visible: boolean) {
      const index = this.hideThresholds.indexOf(thresholdIndex);
      if (index === -1 && !visible) {
        this.hideThresholds.push(thresholdIndex);
      } else if (visible) {
        this.hideThresholds.splice(index, 1);
      }
    },
    setOverviewChartRange(range: string[]) {
      this.overviewChartRange = range;
      this.loadOverview();
      this.setDetailChartRange(range);
    },
    setDetailChartRange(range: string[]) {
      this.detailChartRange = range;
      this.loadChartDetailData(range);
    },
    loadChartDetailData(range: string[]) {
      const startDate = range[0];
      const endDate = range[1];
      this.loadingDetailData = true;
      getSignalTimeseriesForSignal(this.id, startDate, endDate, 100).then(
        (response) => {
          this.loadingDetailData = false;
          this.loaded = true;
          this.error = false;
          // Map the date to a date object
          this.sensorDetails = response.data.signals.map((sensor) => ({
            ...sensor,
            timeseries: sensor.timeseries.map((value) => ({
              ...value,
              startDate: moment(value.startDate),
              endDate: moment(value.endDate),
            })),
          }));
        },
        () => {
          this.loadingDetailData = false;
          this.loaded = false;
          this.error = true;
        }
      );
    },
    async loadOverview() {
      const startDateIso = this.overviewChartRange[0];
      const endDateIso = this.overviewChartRange[1];

      const response = await getSignalTimeseriesForSignal(
        this.id,
        startDateIso,
        endDateIso,
        100
      ).catch((error) => {
        this.loaded = false;
        this.error = true;
        throw error;
      });

      this.loaded = true;
      this.error = false;
      // Map the date to a date object
      this.sensors = response.data.signals.map((sensor) => ({
        ...sensor,
        timeseries: sensor.timeseries.map((value) => ({
          ...value,
          startDate: moment(value.startDate),
          endDate: moment(value.endDate),
        })),
      }));
      // initialize the detailed view to the overview

      this.sensorDetails = this.sensors;
    },
    loadComments() {
      this.loadingStateComments = LoadingState;
      getComments({
        referenceId: this.id,
        referenceType: "signal-timeline",
      }).then(
        (response) => {
          this.loadingStateComments = {
            ...LoadedState,
            result: response.data.reverse(),
          };
        },
        (error) => {
          this.loadingStateComments = ErrorState;
        }
      );
    },
    commentsInRange(start: Date, end: Date): GenericComment[] {
      return this.loadingStateComments.result.filter(
        (comment: GenericComment) =>
          moment(comment.referenceStart).isBefore(end) &&
          moment(comment.referenceEnd).isAfter(start)
      );
    },
    sendComment({
      message,
      referenceStart,
      referenceEnd,
    }: {
      message: string;
      referenceStart: string;
      referenceEnd: string;
    }) {
      addComment({
        value: message,
        format: "plain",
        referenceId: this.id,
        referenceType: "signal-timeline",
        referenceStart: referenceStart, //this.xAxis.invert(this.marker.startX).toISOString(),
        referenceEnd: referenceEnd, //this.xAxis.invert(this.marker.endX).toISOString(),
      }).then((result) => {
        this.loadingStateComments.result.push(result.data);
      });
    },
    setChartHeight() {
      const heightContainer = this.$refs[
        "signalChartHeightContainer"
      ] as HTMLElement;
      const widthContainer = this.$refs[
        "signalChartWidthContainer"
      ] as HTMLElement;
      if (heightContainer !== undefined) {
        // NOTE: remove the height of the fixed elements underneath the chart and some padding, should be calculated as well
        this.signalChartHeight = heightContainer.clientHeight - 250;
      }
      if (widthContainer !== undefined) {
        this.signalChartWidth = widthContainer.clientWidth - 36;
      }
    },
  },
  watch: {
    loaded(isLoaded) {
      if (isLoaded) {
        Vue.nextTick(this.setChartHeight);
      }
    },
  },
  mounted() {
    this.loadOverview();
    this.loadComments();
    this.$store.dispatch("aiSettings/loadSignalObjective", this.id);
    this.setChartHeight();
  },
  created() {
    window.addEventListener("resize", this.setChartHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.setChartHeight);
  },
});
