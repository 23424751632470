import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

import { getToken } from '@/store/user_module';
import store from '@/store';
import { log, getUser } from '@/util';
import { jwtDecode } from '@/util';
import { refreshToken } from './user_service/service';
import { logErrorResponse } from '@/util';
import * as auth from '@/store/user_module';
import { dispatchLogout, commitTokenRefresh } from '@/store/user_module/module';
import router from '@/router';
import { publicPages } from '@/router';

// TODO: exclude other base urls and registration endpoints, refresh token
export const AuthInterceptor = (config: AxiosRequestConfig) => {
  // Don't add token on public routes
  if (publicPages.includes(router.currentRoute.path)) {
    return config;
  }

  if (getToken(store) === undefined) {
    auth.dispatchLogout(store);
    return config;
  }
  const token = getToken(store)!.jwtToken;
  if (token != null) {
    config.headers.Authorization = 'Bearer ' + token;
  }
  return config;
};

let isRefreshing = false;
let requestQueue: Array<{
  resolve: (token: string) => void;
  reject: (reason?: any) => void;
}> = [];

export const AuthErrorInterceptor = (error: AxiosError) => {
  const originalRequest = error.config;
  // @ts-ignore: we ignore the line since we added _retry to AxiosConfig, to not get stuck in an infinite loop
  if (error.response && error.response.status === 401 && !originalRequest._retry) {
    log('Auth error, trying to refresh token');
    if (isRefreshing) {
      log('Already refreshing, waiting for new token');
      // already refreshing, push the request to the queue
      return new Promise((resolve, reject) => {
        requestQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest.headers.Authorization = 'Bearer ' + token;
          return axios(originalRequest);
        })
        .catch((err) => {
          return err;
        });
    }

    // Start the token refresh process

    if (
      getToken(store) === undefined ||
      getToken(store)!.refreshToken === undefined
    ) {
      auth.dispatchLogout(store);
      log('No valid refresh token found, logging out');
    }

    // @ts-ignore
    originalRequest._retry = true;
    isRefreshing = true;

    return new Promise(function (resolve, reject) {
      refreshToken(getToken(store)!)
        .then(({ data }) => {
          log('New token received, storing and retrying requests in queue');
          auth.commitTokenRefresh(store, {
            jwtToken: data.token,
            refreshToken: data.refresh,
          });
          originalRequest.headers.Authorization = 'Bearer ' + data.token;
          processQueue(null, data.token);
          resolve(axios(originalRequest));
        })
        .catch((err) => {
          log('Could not get new token, logging out');
          logErrorResponse(err);
          processQueue(err, null);
          reject(err);
          auth.dispatchLogout(store);
        })
        .then(() => {
          isRefreshing = false;
        });
    });
  }
  return Promise.reject(error);
};

const processQueue = (error: any, token: string | null) => {
  requestQueue.forEach((prom) => {
    if (error !== null || token === null) {
      prom.reject();
    } else {
      prom.resolve(token);
    }
  });
  requestQueue = [];
};
