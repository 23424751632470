











import { mapState, mapActions } from "vuex";
import { log } from "@/util";
import * as auth from "@/store/user_module";
import { hasLoginError } from "@/store/user_module";
import Vue from "vue";
import { setNavbarSettings } from "../store/global_variables";
import { forgotPassword, resetPassword } from "@/services/user_service/service";

export default Vue.extend({
  data() {
    return {
      email: "",
      loading: false,
    };
  },
  computed: {
    loginError() {
      return auth.hasLoginError(this.$store);
    },
    loginErrorMessage() {
      return auth.getLoginErrorMessage(this.$store);
    },
    loading() {
      return auth.isLoginLoading(this.$store);
    },
  },
  methods: {
    resetPassword() {
      if (this.loading) return;
      if (this.email === "") {
        this.$message("Please enter your E-Mail address");
        return;
      }
      this.loading = true;
      forgotPassword(this.email)
        .then(() => {
          this.loading = false;
          this.$message(this.$tc("password_reset_sent"));
          this.$router.push({ name: "login" });
        })
        .catch(() => {
          this.loading = false;
          this.$message(this.$tc("generic_error"));
        });
    },
  },
});
