


















import * as auth from "@/store/user_module";
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      email: "",
      password: "",
      requestLoading: false,
      dialogVisible: false,
    };
  },
  computed: {
    loginError(): boolean {
      return auth.hasLoginError(this.$store);
    },
    loginErrorMessage(): string {
      return auth.getLoginErrorMessage(this.$store);
    },
    loading(): boolean {
      return auth.isLoginLoading(this.$store) || this.requestLoading;
    },
  },
  methods: {
    onLoginError(error: string) {
      if (error.includes("terms and conditions")) {
        this.dialogVisible = true;
      } else {
        this.dialogVisible = false;
        this.$message(error);
      }
    },
    signInWithTc() {
      auth.dispatchLogin(this.$store, {
        username: this.email,
        password: this.password,
        termsAndConditions: true,
      });
    },
    signIn() {
      if (this.requestLoading) {
        return;
      }
      this.requestLoading = true;
      if (this.email === "") {
        this.$message("Please enter your username or E-Mail address");
        return;
      } else if (this.password === "") {
        this.$message("Please enter your password");
        return;
      }
      auth
        .dispatchLogin(this.$store, {
          username: this.email,
          password: this.password,
          // NOTE: for now we assume that terms and conditions are accepted
          termsAndConditions: true,
        })
        .finally(() => {
          this.requestLoading = false;
        });
    },
  },
});
