










































































import Vue from "vue";
import c3 from "c3";
import KpiDashboard from "@/components/KpiDashboard.vue";
import moment from "moment";

export default Vue.extend({
  name: "dashboard",
  components: {
    KpiDashboard,
  },
  data() {
    return {
      chart: null,
      barchart: null,
      alerts: [
        {
          type: "Anomaly",
          name: "ADSA-Service",
          machine: "G550a",
          alert: "Temperature anomaly",
          time: "13:17:02",
        },
        {
          type: "Threshold",
          name: "Temperatur",
          machine: "G550a",
          alert: "72,4° over threshold",
          time: "13:17:02",
        },
      ],
      dailyMessages: [
        { name: "total", value: "1850" },
        { name: "thresholdCrosses", value: "1000" },
        { name: "anomalies", value: "850" },
      ],
      monitoring: [
        { name: "sensorValues", value: "367" },
        { name: "thresholds", value: "672" },
        { name: "anomalieDetectors", value: "421" },
      ],
      machineEffectivity: [
        { name: "OEE", value: "64%" },
        { name: "deliveryRate", value: "94,1%" },
        { name: "outputRate", value: "90%" },
      ],
      dailyAverage: [
        { name: "scrap", value: "16%" },
        { name: "idleRate", value: "21%" },
        { name: "standstillRate", value: "3,2%" },
        { name: "defaultRate", value: "1,2%" },
        { name: "maintenanceRate", value: "2%" },
        { name: "yield", value: "86,2%" },
      ],
      dailyPerformace: [
        { name: "throughput", value: "1.440" },
        { name: "cycleTime", value: "0,4" },
        { name: "clockTime", value: "0,52" },
      ],
    };
  },
  computed: {},
  methods: {
    setChart() {
      const colorCodes = [
        "#8EB8E5",
        "#7C99B4",
        "#6B7F82",
        "#461220",
        "#EDADC7",
        "#D199B6",
      ];
      const colors = {};
      [
        "scrap",
        "idleRate",
        "standstillRate",
        "defaultRate",
        "maintenanceRate",
        "yield",
      ].forEach((item, index) => {
        // @ts-ignore
        colors[this.$tc(item)] = colorCodes[index];
      });

      this.chart = c3.generate({
        bindto: "#chart",
        data: {
          columns: [
            [this.$t("scrap"), 69.9, 49, 30, 93, 16],
            [this.$t("idleRate"), 22.8, 69.8, 28.1, 89.5, 21],
            [this.$t("standstillRate"), 15, 29.7, 69.8, 10.6, 3.2],
            [this.$t("defaultRate"), 34.7, 91.4, 65.7, 23.3, 1.2],
            [this.$t("maintenanceRate"), 13.1, 75.9, 85.7, 19.7, 2],
            [this.$t("yield"), 50, 20, 10, 40, 86.2],
          ],
          colors,
        },
        axis: {
          x: {
            type: "category",
            categories: new Array(5)
              .fill(null)
              .map((_, i) => moment().subtract(i, "days").format("dd"))
              .reverse(),
          },
        },
      });

      const barColors = {};
      ["throughput", "cycleTime", "clockTime"].forEach((item, index) => {
        // @ts-ignore
        barColors[this.$tc(item)] = colorCodes[index];
      });

      this.barchart = c3.generate({
        bindto: "#barchart",
        data: {
          columns: [
            [this.$t("throughput"), 30, 200, 100, 400, 150, 250],
            [this.$t("cycleTime"), 130, 100, 140, 200, 150, 50],
            [this.$t("clockTime"), 130, 100, 140, 200, 150, 50],
          ],
          type: "bar",
          colors: barColors,
        },
        axis: {
          x: {
            type: "category",
            categories: new Array(6)
              .fill(null)
              .map((_, i) => moment().subtract(i, "months").format("MMM"))
              .reverse(),
          },
        },
        bar: {
          width: {
            ratio: 0.5, // this makes bar width 50% of length between ticks
          },
          // or
          //width: 100 // this makes bar width 100px
        },
      });
    },
  },
  mounted() {
    this.setChart();
  },
});
