













































































































































import {
  AiListElement,
  AiSetting,
  SignalAis,
  SignalOverview,
} from "@/services/machine_service";
import Loading from "@/components/Loading.vue";
import HtmlComponent from "@/components/AiSettingComponents/HtmlComponent.vue";
import LevelValueComponent from "@/components/AiSettingComponents/LevelValueComponent.vue";
import NumberComponent from "@/components/AiSettingComponents/NumberComponent.vue";
import BooleanComponent from "@/components/AiSettingComponents/BooleanComponent.vue";
import Vue from "vue";
import { ExtendedVue } from "vue/types/vue";
import { mapGetters } from "vuex";
import { aiMappingsLoadingState } from "@/store/ai_settings";
import { formatDate } from "../util";
import { signalAiSettings } from "../store/ai_settings/ai_settings";

export default Vue.extend({
  components: {
    Loading,
  },
  props: {
    machineId: String,
    signalId: String,
  },
  data: () => ({
    aiNameBeforeEdit: "",
    aiName: "",
    analysisCreateType: null,
    createEditLoading: false,
    createEditError: false,
    showCreateEditDialog: false,
    editingRowId: null,
    analysisMarkedForDeletion: null,
    formatDate,
    selectedSignal: null,
  }),
  computed: {
    ...mapGetters("aiSettings", [
      "signalAiSettings",
      "aiMappingsLoadingState",
      "aiList",
      "aiListLoadingState",
      "mergedAiSettings",
    ]),
    ...mapGetters("machineModule", ["signalsLoadingState"]),
    isEditing(): boolean {
      return this.editingRowId !== null;
    },
    machineSignals(): SignalOverview[] {
      return this.signalsLoadingState.result.filter(
        (signal: SignalOverview) => signal.machine.id === this.machineId
      );
    },
    isCreating(): boolean {
      return this.editingRowId === null;
    },
    showDeleteDialog(): boolean {
      return this.analysisMarkedForDeletion !== null;
    },
    analysisTypes(): { label: string; value: string }[] {
      return this.aiListLoadingState.loaded
        ? this.aiList.map((item: AiListElement) => ({
            label: item.name,
            value: item.id,
          }))
        : [];
    },
    analysisCreateItem(): AiListElement {
      return this.analysisCreateType !== null
        ? this.aiList.find(
            (item: AiListElement) => item.id === this.analysisCreateType
          )
        : null;
    },
    isFormCompleted(): boolean {
      if (this.analysisCreateItem === null) {
        return false;
      }
      return this.analysisCreateItem.settings.reduce(
        (aggregate, current) =>
          aggregate && (current.isBasic ? this.hasValueSet(current.id) : true),
        true as boolean
      );
    },
    loading(): boolean {
      return (
        aiMappingsLoadingState(this.$store).loading ||
        this.aiListLoadingState.loading
      );
    },
    loaded(): boolean {
      return (
        aiMappingsLoadingState(this.$store).loaded &&
        this.aiListLoadingState.loaded
      );
    },
    error(): boolean {
      return (
        aiMappingsLoadingState(this.$store).error ||
        this.aiListLoadingState.error
      );
    },
  },
  methods: {
    hasValueSet(id: string): boolean {
      const setting = this.mergedAiSettings.find(
        (item: AiSetting) => item.id === id
      );
      if (setting === undefined) {
        return false;
      }
      return setting.value !== undefined;
    },
    formatUpdateDate(row: any): string {
      if (row.lastDataUpdate !== null) {
        return formatDate(row.lastDataUpdate);
      }
      return this.$tc("no_data");
    },
    loadData(): void {
      this.$store.dispatch("aiSettings/loadAiMappings", {
        machineId: this.machineId,
        signalId: this.signalId,
      });
      this.$store.dispatch("aiSettings/loadAis");
      this.$store.dispatch("machineModule/loadSignals");
    },
    showDeleteAiDialog(id: string) {
      this.analysisMarkedForDeletion = id;
    },
    deleteAnalysis() {
      this.$store
        .dispatch("aiSettings/deleteAi", this.analysisMarkedForDeletion.id)
        .then(() =>
          this.$store.dispatch("aiSettings/loadAiMappings", {
            machineId: this.machineId,
            signalId: this.signalId,
          })
        );

      this.analysisMarkedForDeletion = null;
    },
    startAi(row: AiListElement) {
      this.$store.dispatch("aiSettings/startAi", row.id);
    },
    stopAi(row: AiListElement) {
      this.$store.dispatch("aiSettings/stopAi", row.id);
    },
    closeDeleteDialog() {
      this.analysisMarkedForDeletion = null;
    },
    currentSettingsById(id: string) {
      return this.mergedAiSettings.find((item: AiSetting) => item.id === id);
    },
    editRow(row: AiListElement) {
      this.analysisCreateType = row.aiId;
      this.editingRowId = row.id;
      this.aiName = row.name;
      this.aiNameBeforeEdit = row.name;
      this.showCreateEditDialog = true;
      this.$store.dispatch("aiSettings/loadMappingSettings", row.id);
    },
    handleClose() {
      this.$store.commit("aiSettings/clearSettingPatches");
      this.$store.commit("aiSettings/clearLoadedSettings");
      this.editingRowId = null;
      this.showCreateEditDialog = false;
      this.aiName = "";
      this.createEditError = false;
    },
    settingComponentByName(name: string): ExtendedVue<any, any, any, any, any> {
      switch (name) {
        case "html":
          return HtmlComponent;
        case "levelValues":
          return LevelValueComponent;
        case "number":
          return NumberComponent;
        case "boolean":
          return BooleanComponent;
      }
    },
    createAnalysis() {
      if (this.signalId === undefined && this.signalId === null) {
        return;
      }
      this.createEditLoading = true;
      this.createEditError = false;
      this.$store
        .dispatch("aiSettings/createSignalAi", {
          aiId: this.analysisCreateItem.id,
          signalId:
            this.signalId !== undefined ? this.signalId : this.selectedSignal,
          name: this.aiName,
        })
        .then(
          (response) => {
            this.createEditLoading = false;
            this.handleClose();
          },
          (error) => {
            this.createEditLoading = false;
            this.createEditError = true;
          }
        );
    },
    editAnalysis() {
      this.createEditLoading = true;
      this.createEditError = false;

      Promise.all([
        this.$store.dispatch("aiSettings/editSignalAi", {
          mappingId: this.editingRowId,
        }),
        this.$store.dispatch("aiSettings/editAiMapping", {
          id: this.editingRowId,
          name: this.aiName,
        }),
      ]).then(
        (response) => {
          this.createEditLoading = false;
          this.handleClose();
        },
        (error) => {
          this.createEditLoading = false;
          this.createEditError = true;
        }
      );
    },
  },
  watch: {
    analysisCreateType() {
      this.$store.commit("aiSettings/clearSettingPatches");
    },
  },
  mounted(): void {
    this.loadData();
  },
});
