





































import Vue from "vue";
import Loading from "@/components/Loading.vue";
import { MachineOverview, MachineTwin } from "@/services/machine_service/types";
import {
  getDataSources,
  DataSource,
  removeDataSource,
} from "@/services/data_source_service/";
import { setBreadCrumbs } from "@/store/global_variables";

export default Vue.extend({
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      loaded: false,
      error: false,
      errorMessage: "",
      overview: [] as DataSource[],
      search: "",
    };
  },
  computed: {
    filteredOverview(): Array<{
      host: string;
      username: string;
      version: string;
      gateway: string;
    }> {
      return this.overview
        .map((source) => {
          const config = JSON.parse(source.config);
          return {
            uuid: source.uuid,
            host: config.address,
            username: config.username,
            version: source.version,
            gateway: source.gateway,
          };
        })
        .filter((item) =>
          item.host.toLowerCase().includes(this.search.toLowerCase())
        );
    },
  },
  methods: {
    rowclick(row: any) {
      this.$router.push({
        name: "dataSourceEdit",
        params: { id: row.uuid },
      });
    },
    loadData() {
      this.loading = true;
      getDataSources().then(
        (response) => {
          this.overview = response.data;
          this.loading = false;
          this.loaded = true;
          this.error = false;
        },
        (error) => {
          this.error = true;
          this.loading = false;
          this.loaded = false;
          this.overview = [];
        }
      );
    },
    deleteDataSource(source: DataSource) {
      this.loading = true;
      removeDataSource(source.uuid).then(this.loadData, (_) => {
        this.loading = false;
        this.error = true;
      });
    },
  },
  mounted() {
    this.loadData();
    setBreadCrumbs(this.$store, [
      {
        name: "data_source_overview",
        i18n: true,
        route: { name: "dataSources" },
      },
    ]);
  },
});
