









































import Vue from "vue";
import Loading from "@/components/Loading.vue";
import {
  createMachineProperty,
  createTwin,
  getTwin,
  patchTwin,
  setPropertyValue,
} from "../services/machine_service/service";
import {
  MachineProperty,
  MachineTwinCreate,
} from "../services/machine_service/types";
import { setBreadCrumbs } from "@/store/global_variables";

export default Vue.extend({
  components: {
    Loading,
  },
  props: {
    id: { type: String, required: false },
  },
  data() {
    return {
      name: "",
      machineType: "",
      mappingName: "",
      loading: false,
      error: false,
      cycleTime: 1,
    };
  },
  computed: {
    isEdit(): boolean {
      return this.id !== undefined;
    },
    isSendButtonActivated(): boolean {
      return this.name !== "";
    },
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    sendData() {
      if (this.name === "") {
        return;
      }
      this.loading = true;

      const twin = {
        shortname: "",
        name: this.name,
        type: this.machineType,
        status: "up",
        timeStampIdentifier: ["date"],
        attributes: {},
        active: true,
        cycleTime: this.cycleTime,
        isTemplate: false,
      } as MachineTwinCreate;

      if (this.isEdit) {
        patchTwin(this.id, twin).then(
          (_) => {
            this.loading = false;
            this.$router.push({ name: "machineDetail" });
          },
          (_) => {
            this.loading = false;
            this.error = false;
          }
        );
      } else {
        //  NOTE: at first we create a twin, then we HAVE to create a property with the machine id
        createTwin(twin).then(
          (response) => {
            const property = {
              name: "machineName", // TODO change later on to user-selected name if needed. Is only matching the standard force-edge data at the moment
              type: "text",
              isManual: true,
              isIdentifier: true,
              isTimeSeries: false,
              // THESE ARE HARDCODED PROPERTIES REQUIRED BY THE BACKEND
              order: 0,
              unit: "",
              mappingName: twin.name, // TODO mapping on the machine name - change later on to user-selected name if needed
            } as MachineProperty;
            createMachineProperty(response.data.uuid, property).then(
              (propertyResponse) => {
                setPropertyValue(
                  response.data.uuid,
                  propertyResponse.data.uuid,
                  this.name
                ).then((_) => {
                  this.loading = false;
                  this.$router.push({ name: "machine" });
                });
              }
            );
          },
          (error) => {
            this.loading = false;
          }
        );
      }
    },
  },
  mounted() {
    if (this.isEdit) {
      this.loading = true;
      getTwin(this.id).then(
        (response) => {
          const machine = response.data;
          this.name = machine.name;
          this.machineType = machine.type;
          this.loading = false;
        },
        (_) => {
          this.error = true;
          this.loading = false;
        }
      );
    }
    setBreadCrumbs(this.$store, [
      {
        name: "add_machine",
        i18n: true,
        route: { name: "machineCreate" },
      },
    ]);
  },
});
