import { AxiosPromise } from 'axios';
import axios from 'axios';
import { CommentQuery, CommentCreate } from './types';

export function getComments(query: CommentQuery): AxiosPromise<Comment[]> {
    return axios.post(`/comments/search`, query);
}

export function addComment(comment: CommentCreate): AxiosPromise<Comment> {
    return axios.post('/comments', comment);
}

export function deleteComment(id: string): AxiosPromise<void> {
    return axios.delete(`/comments/${id}`);
}