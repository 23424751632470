






























































import Vue from "vue";
import Loading from "@/components/Loading.vue";
import {
  deleteTwin,
  getMachineTwins,
  getTwins,
} from "../services/machine_service/service";
import { MachineOverview, MachineTwin } from "@/services/machine_service/types";
import { userHasRole } from "../util/roles";
import { setBreadCrumbs } from "@/store/global_variables";

export default Vue.extend({
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      loaded: false,
      error: false,
      errorMessage: "",
      overview: [] as MachineOverview[],
      search: "",
    };
  },
  computed: {
    userCanEdit(): boolean {
      return userHasRole(["expert", "global_admin", "system_admin"]);
    },
    filteredOverview(): MachineOverview[] {
      return this.overview.filter((item: MachineOverview) =>
        item.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    loadData() {
      this.loading = true;
      getTwins().then(
        (response) => {
          this.overview = response.data.map((item: MachineTwin) => ({
            id: item.uuid,
            name: item.name,
            signals: undefined,
            kpis: undefined,
            workplace: "N/A",
            productionLine: "N/A",
            anomaliesLastWeek: undefined,
            anomaliesLastDay: undefined,
          }));
          this.loading = false;
          this.loaded = true;
          this.error = false;
        },
        (error) => {
          this.error = true;
          this.loading = false;
          this.loaded = false;
          this.overview = [];
        }
      );
      // TODO: This is using the endpoint with actual aggregations
      // getMachineTwins().then(response => {
      //     this.overview = response.data.twins;
      //     this.loading = false ;
      //     this.loaded = true;
      //     this.error = false ;
      // }, (error) => {
      //     this.error = true;
      //     this.loading = false ;
      //     this.loaded = false ;
      //     this.overview = [];
      // })
    },
    rowclick(row: MachineOverview) {
      this.$router.push({
        name: "machineDetail",
        params: { id: row.id },
      });
    },
    deleteMachine(row: MachineOverview) {
      this.loading = true;
      deleteTwin(row.id).then(this.loadData, (_) => {
        this.error = true;
        this.loading = false;
      });
    },
  },
  mounted() {
    this.loadData();
    setBreadCrumbs(this.$store, [
      {
        name: "machineOverview",
        i18n: true,
        route: { name: "machine" },
      },
    ]);
  },
});
