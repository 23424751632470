import { AxiosPromise } from 'axios';
import axios from 'axios';
import { pipelineRoot } from '@/preferences';
import { DataSource } from './types';

export function getDataSources(): AxiosPromise<DataSource[]> {
    return axios.get(`${pipelineRoot}`);
}

export function createDataSource(data: DataSource): AxiosPromise<any> {
    return axios.post(`${pipelineRoot}`, data);
}


export function updateDataSource(data: DataSource): AxiosPromise<any> {
    return axios.patch(`${pipelineRoot}/${data.uuid}`, data);
}

export function removeDataSource(id: string): AxiosPromise<any> {
    return axios.delete(`${pipelineRoot}/${id}`);
}

