
















import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      showDialog: false,
      message: "",
    };
  },
});
