



















































































































































import Vue from "vue";
import Loading from "@/components/Loading.vue";
import moment, { Moment } from "moment";
import CommentSidebar from "@/components/CommentSidebar.vue";
import SignalChart from "@/components/SignalChart.vue";
import { ColorMap } from "@/components/SignalChart.vue";
import SignalOverview from "@/components/SignalOverview.vue";
import MachineDashboard from "@/components/MachineDashboard.vue";
import {
  getAnomaliesForMachine,
  getSignalObjective,
  getSignalTimeseriesForTwin,
  getTwin,
  MachineTwin,
  SignalAnomalies,
  SignalObjective,
  SignalTimeseries,
} from "@/services/machine_service";
import { userHasRole } from "@/util/roles";
import { generateColors } from "@/util/colorGenerator";
import {
  DefaultLoadState,
  ErrorState,
  LoadedState,
  LoadingState,
} from "../store/types";
import { addComment, getComments } from "@/services/comment_service/service";
import { GenericComment } from "@/services/comment_service/types";
import EditAnalysis from "@/components/EditAnalysis.vue";
import TargetThresholds from "@/components/TargetThresholds.vue";
import SignalAlerts from "@/components/SignalAlerts.vue";
import { setBreadCrumbs } from "@/store/global_variables";

interface SignalObjectiveWithId extends SignalObjective {signalId: string};

export default Vue.extend({
  components: {
    Loading,
    CommentSidebar,
    SignalChart,
    SignalOverview,
    EditAnalysis,
    TargetThresholds,
    SignalAlerts,
    MachineDashboard,
  },
  props: {
    id: String,
  },
  data() {
    return {
      activeTab: "overview",

      loading: false,
      loadingDetailData: false,
      loadingAnomalies: false,
      loadingStateComments: DefaultLoadState,
      loadingStateObjectives: DefaultLoadState,
      loaded: false,
      error: false,

      machineDetails: null as MachineTwin,
      sensors: [] as SignalTimeseries[],
      sensorDetails: [] as SignalTimeseries[],
      anomalies: [] as SignalAnomalies[],
      objectives: [] as SignalObjectiveWithId[],

      debounceOverviewTimeout: null,
      debounceChartTimeout: null,

      signalChartWidth: 980,
      signalChartHeight: 300,
      detailChartRange: [
        moment().subtract(1, "days").toISOString(),
        moment().toISOString(),
      ],
      overviewChartRange: [
        moment().subtract(1, "days").toISOString(),
        moment().toISOString(),
      ],

      hideSignals: [],
      hideAnomalies: [],
    };
  },
  computed: {
    filteredSignalObjectives(): SignalObjectiveWithId[] {
      return this.objectives.filter((o: SignalObjectiveWithId) => !this.hideSignals.includes(o.signalId));
    },
    visibleAnomalies(): SignalAnomalies[] {
      return this.anomalies.filter(
        (item) => !this.hideAnomalies.includes(item.aiMappingId)
      );
    },
    colors(): ColorMap[] {
      const colors = generateColors(
        this.sensorDetails.length + this.anomalies.length
      );

      return [
        ...this.sensorDetails.map((item, index) => ({
          id: item.propertyId,
          color: colors[index],
        })),
        ...this.anomalies.map((item, index) => ({
          id: item.aiMappingId,
          color: colors[this.sensorDetails.length + index],
        })),
      ];
    },
    userCanEdit(): boolean {
      return userHasRole(["expert", "global_admin", "system_admin"]);
    },
    visibleSensorOverview(): any[] {
      return this.sensors.filter(
        (sensor) => !this.hideSignals.includes(sensor.propertyId)
      );
    },
    visibleSensorDetails(): any[] {
      return this.sensorDetails.filter(
        (sensor) => !this.hideSignals.includes(sensor.propertyId)
      );
    },
  },
  methods: {
    loadMachineDetails(): void {
      getTwin(this.id).then(
        (response) => (this.machineDetails = response.data),
        (_) => {
          this.error = true;
        }
      );
    },
    async loadSignalObjectives() {
      this.loadingStateObjectives = LoadingState;
      const result = await Promise.all(
        this.sensors.map((sensor) =>
          getSignalObjective(sensor.propertyId)
            .then((response) => ({
              signalId: sensor.propertyId,
              ...response.data,
            }))
            .catch((error) => undefined)
        )
      );
      this.loadingStateObjectives = LoadedState;
      this.objectives = result.filter((result) => result !== undefined);
    },
    // onResize(element: HTMLElement) {
    //   this.signalChartWidth = element.clientWidth - 50;
    // },
    async reloadData() {
      await this.loadMachineOverview();
      this.loadChartDetailData();
    },
    setOverviewChartRange(range: string[]) {
      this.overviewChartRange = range;
      this.loadMachineOverview();
      this.loadAnomalies();
      this.setDetailChartRange(range);
    },
    setDetailChartRange(range: string[]) {
      this.detailChartRange = range;
      const start = moment(range[0]);
      const end = moment(range[1]);
      const overviewStart = moment(this.overviewChartRange[0]);
      const overviewEnd = moment(this.overviewChartRange[1]);
      if (start.isBefore(overviewStart) || end.isAfter(overviewEnd)) {
        this.setOverviewChartRange([
          moment.min(start, overviewStart).toISOString(),
          moment.max(end, overviewEnd).toISOString(),
        ]);
      }
      this.loadChartDetailData();
    },
    loadChartDetailData() {
      const startDateIso = this.detailChartRange[0];
      const endDateIso = this.detailChartRange[1];
      this.loadingDetailData = true;
      getSignalTimeseriesForTwin(this.id, startDateIso, endDateIso, 100).then(
        (response) => {
          this.loadingDetailData = false;
          this.loaded = true;
          this.error = false;
          // Map the date to a date object
          this.sensorDetails = response.data.signals.map((sensor) => ({
            ...sensor,
            timeseries: sensor.timeseries.map((value) => ({
              ...value,
              startDate: moment(value.startDate),
              endDate: moment(value.endDate),
            })).filter((val) => !!val.endDate),
          }));
        },
        () => {
          this.loadingDetailData = false;
          this.loaded = false;
          this.error = true;
        }
      );
    },
    async loadMachineOverview() {
      const startDateIso = this.overviewChartRange[0];
      const endDateIso = this.overviewChartRange[1];

      // localStorage.setItem('machineDetailsStartDate', startDateIso)
      // localStorage.setItem('machineDetailsEndDate', endDateIso)

      const response = await getSignalTimeseriesForTwin(
        this.id,
        startDateIso,
        endDateIso,
        100
      ).catch((error) => {
        this.loading = false;
        this.loaded = false;
        this.error = true;
        throw error;
      });

      this.loading = false;
      this.loaded = true;
      this.error = false;
      // Map the date to a date object
      this.sensors = response.data.signals.map((sensor) => ({
        ...sensor,
        timeseries: sensor.timeseries.map((value) => ({
          ...value,
          startDate: moment(value.startDate),
          endDate: moment(value.endDate),
        })),
      }));
      // initialize the detailed view to the overview
      this.sensorDetails = this.sensors;
    },
    loadComments() {
      this.loadingStateComments = LoadingState;
      getComments({
        referenceId: this.id,
        referenceType: "timeline",
      }).then(
        (response) => {
          this.loadingStateComments = {
            ...LoadedState,
            result: response.data.reverse(),
          };
        },
        (error) => {
          this.loadingStateComments = ErrorState;
        }
      );
    },
    loadAnomalies() {
      const startDateIso = this.overviewChartRange[0];
      const endDateIso = this.overviewChartRange[1];

      this.loadingAnomalies = true;
      getAnomaliesForMachine(this.id, startDateIso, endDateIso).then(
        (response) => {
          this.loadingAnomalies = false;
          this.anomalies = response.data.anomalies.map((anomaly) => ({
            ...anomaly,
            timeseries: anomaly.timeseries.filter((val) => !!val.endDate).map((value) => ({
              ...value,
              startDate: moment(value.startDate),
              endDate: moment(value.endDate),
            })),
          }));
        },
        (error) => {
          this.loadingAnomalies = false;
        }
      );
    },

    getColor(sensorId: string) {
      const colorMap = this.colors.find((item) => item.id === sensorId);
      if (colorMap) {
        return colorMap.color;
      }
      return "hsl(165, 51%, 55%)";
    },

    // TODO: move comments to store?!

    commentsInRange(start: Date, end: Date): Comment[] {
      return this.loadingStateComments.result.filter(
        (comment: GenericComment) =>
          moment(comment.referenceStart).isBefore(end) &&
          moment(comment.referenceEnd).isAfter(start)
      );
    },
    sendComment({
      message,
      referenceStart,
      referenceEnd,
    }: {
      message: string;
      referenceStart: string;
      referenceEnd: string;
    }) {
      addComment({
        value: message,
        format: "plain",
        referenceId: this.id,
        referenceType: "timeline",
        referenceStart: referenceStart, //this.xAxis.invert(this.marker.startX).toISOString(),
        referenceEnd: referenceEnd, //this.xAxis.invert(this.marker.endX).toISOString(),
      }).then((result) => {
        this.loadingStateComments.result.push(result.data);
      });
    },
    switchHideSignal(id: string, visible: boolean) {
      const index = this.hideSignals.indexOf(id);
      if (index === -1 && !visible) {
        this.hideSignals.push(id);
      } else if (visible) {
        this.hideSignals.splice(index, 1);
      }
    },
    switchHideAnomalies(id: string, visible: boolean) {
      const index = this.hideAnomalies.indexOf(id);
      if (index === -1 && !visible) {
        this.hideAnomalies.push(id);
      } else if (visible) {
        this.hideAnomalies.splice(index, 1);
      }
    },
    setChartHeight() {
      const heightContainer = this.$refs[
        "signalChartHeightContainer"
      ] as HTMLElement;
      const widthContainer = this.$refs[
        "signalChartWidthContainer"
      ] as HTMLElement;
      if (heightContainer !== undefined) {
        // NOTE: remove the height of the fixed elements underneath the chart and some padding, should be calculated as well
        this.signalChartHeight = heightContainer.clientHeight - 250;
      }
      if (widthContainer !== undefined) {
        this.signalChartWidth = widthContainer.clientWidth - 36;
      }
    },
  },
  watch: {
    loaded(isLoaded) {
      if (isLoaded) {
        Vue.nextTick(this.setChartHeight);
      }
    },
    activeTab() {
      Vue.nextTick(this.setChartHeight);
    },
    sensors() {
      this.loadSignalObjectives();
    },
  },
  mounted() {
    this.loading = true;
    this.loadMachineDetails();
    this.loadMachineOverview();
    this.loadComments();
    this.setChartHeight();
    setBreadCrumbs(this.$store, [
      {
        name: "machineDetails",
        i18n: true,
        route: { name: "machineDetail", params: { id: this.id } },
      },
    ]);
  },
  created() {
    window.addEventListener("resize", this.setChartHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.setChartHeight);
  },
});
