import { machineApiRoot, aiRoot, twinsRoot } from '@/preferences';
import { AxiosPromise } from 'axios';
import axios from 'axios';
import { MachineTwin, MachineProperty, MachineOverview, SignalOverview, SignalTimeseries, SignalAnomalies, LearningAlgorithm, SignalAis, AiListElement, AiSettings, Material, Operation, AiMappingAlert, MachineTwinCreate, AlertMetadata } from './types';
import { signalsRoot } from '../../preferences';
import { SignalObjective } from '.';
import moment from 'moment';

export function getTwins(): AxiosPromise<MachineTwin[]> {
  return axios.get(`${machineApiRoot}/machines`);
}

export function getTwin(id: string): AxiosPromise<MachineTwin> {
  return axios.get(`${machineApiRoot}/machines/${id}`);
}

export function deleteTwin(id: string): AxiosPromise<any> {
  return axios.delete(`${machineApiRoot}/machines/${id}`);
}

export function getMachineTwins(): AxiosPromise<{ twins: MachineOverview[] }> {
  return axios.get(`${machineApiRoot}/machines`);
}

export function createTwin(twin: MachineTwinCreate): AxiosPromise<MachineTwin> {
  return axios.post(`${machineApiRoot}/machines`, twin);
}

export function patchTwin(id: string, twin: MachineTwinCreate): AxiosPromise<MachineTwin> {
  return axios.patch(`${machineApiRoot}/machines/${id}`, twin);
}

export function createMachineProperty(twinId: string, property: MachineProperty): AxiosPromise<MachineProperty> {
  return axios.post(`${twinsRoot}/${twinId}/properties`, property);
}

export function updateMachineProperty(twinId: string, property: MachineProperty): AxiosPromise<MachineProperty> {
  return axios.patch(`${twinsRoot}/${twinId}/properties/${property.uuid}`, property);
}

export function setPropertyValue(twinId: string, propertyId: string, value: string) {
  return axios.post(`${twinsRoot}/${twinId}/properties/${propertyId}/values`, { value });
}

export function deleteMachineProperty(twinId: string, propertyId: string): AxiosPromise<MachineProperty> {
  return axios.delete(`${twinsRoot}/${twinId}/properties/${propertyId}`);
}

export function getProperties(twinId: string): AxiosPromise<MachineProperty[]> {
  return axios.get(`${twinsRoot}/${twinId}/properties`);
}

export function getSignals(params?: {machineUuid?: string, [key: string]: string}): AxiosPromise<{ signals: SignalOverview[] }> {
  return axios.get(`${signalsRoot}?${new URLSearchParams(params)}`);
}

export function getAi(): AxiosPromise<{ result: AiListElement[] }> {
  return axios.get(`${aiRoot}`);
}

export function getAisForSignal(signalId: string): AxiosPromise<{ result: SignalAis[] }> {
  return axios.get(`${signalsRoot}/aiMappings?${new URLSearchParams({ signalId })}`);
}

export function getAisForMachine(machineId: string): AxiosPromise<{ result: SignalAis[] }> {
  return axios.get(`${signalsRoot}/aiMappings?${new URLSearchParams({ machineId })}`);
}


export function setAiMappingStatus(aiMappingId: string, status: string) {
  return axios.put(`/machineApi/aiMappings/${aiMappingId}/status`, { status })
}

export function createAiMapping(signalId: string, aiId: string, name: string, settings: AiSettings): AxiosPromise<any> {
  return axios.post(`${signalsRoot}/${signalId}/aiMappings`, { aiId, name, configuration: settings });
}

export function updateAiMapping(aiMappingId: string, name: string) {
  return axios.patch(`/machineApi/aiMappings/${aiMappingId}`, { name })
}

export function updateAiSettings(aiMappingId: string, settings: AiSettings) {
  return axios.put(`/machineApi/aiMappings/${aiMappingId}/config`, { configuration: settings })
}

export function getAiMapping(aiMappingId: string) {
  return axios.get(`/machineApi/aiMappings/${aiMappingId}`)
}

export function deleteAiMapping(aiMappingId: string) {
  return axios.delete(`/machineApi/aiMappings/${aiMappingId}`)
}

export function getOperations(): AxiosPromise<{ operations: Operation[] }> {
  return axios.get(`${machineApiRoot}/operations`)
}

export function getMaterials(): AxiosPromise<{ materials: Material[] }> {
  return axios.get(`${machineApiRoot}/materials`)
}

export function createMaterial(material: Omit<Material, "uuid">): AxiosPromise<void> {
  return axios.post(`${machineApiRoot}/materials/`, material);
}

export function getSignalTimeseriesForTwin(twinId: string, startDate: string, endDate: string, buckets: number): AxiosPromise<{ signals: SignalTimeseries[] }> {
  const paramsObject = { type: 'twin', id: twinId, startDate, endDate, buckets: buckets.toString() };
  return axios.get(`${machineApiRoot}/timeseries/signals?${new URLSearchParams(paramsObject)}`)
    // TODO: The backend has currently a bug which shuffles the array https://github.com/inctec/smc-foundation/issues/171
    .then(response => ({ ...response, data: { ...response.data, signals: response.data.signals.map((signal: SignalTimeseries) => ({ ...signal, timeseries: signal.timeseries.sort((a, b) => moment(a.startDate).isBefore(moment(b.startDate)) ? -1 : 1) })) } }));
}

export function getSignalTimeseriesForSignal(signalId: string, startDate: string, endDate: string, buckets: number): AxiosPromise<{ signals: SignalTimeseries[] }> {
  const paramsObject = { type: 'signal', id: signalId, startDate, endDate, buckets: buckets.toString() };
  return axios.get(`${machineApiRoot}/timeseries/signals?${new URLSearchParams(paramsObject)}`)
    // TODO: The backend has currently a bug which shuffles the array https://github.com/inctec/smc-foundation/issues/171
    .then(response => ({ ...response, data: { ...response.data, signals: response.data.signals.map((signal: SignalTimeseries) => ({ ...signal, timeseries: signal.timeseries.sort((a, b) => moment(a.startDate).isBefore(moment(b.startDate)) ? -1 : 1) })) } }));
}



export function getAnomaliesForMachine(twinId: string, startDate: string, endDate: string): AxiosPromise<{ anomalies: SignalAnomalies[] }> {
  const params = { type: 'twin', id: twinId, startDate, endDate }
  return axios.get(`${machineApiRoot}/timeseries/alerts?${new URLSearchParams(params)}`);
}

export type PagingQuery = { startDate?: string, endDate?: string, pageSize?: string, currentPage?: string, orderBy?: string, orderDirection?: 'asc' | 'desc' };

export function getAnomaliesForSignal(signalId: string, queries: PagingQuery): AxiosPromise<{ anomalies: SignalAnomalies[], pagination: Pagination }> {
  const params = { type: 'signal', id: signalId, ...queries }
  return axios.get(`${machineApiRoot}/timeseries/alerts?${new URLSearchParams(params)}`);
}

export function searchAlertsForSignal(signalId: string, pagingQuery: PagingQuery): AxiosPromise<{ alerts: AiMappingAlert[], pagination: Pagination }> {
  return axios.post(`${machineApiRoot}/timeseries/alerts/_search?${new URLSearchParams(pagingQuery)}`, { signalUuid: signalId });
}

export function searchAlertsForMachine(twinId: string, pagingQuery: PagingQuery): AxiosPromise<{ alerts: AiMappingAlert[], pagination: Pagination }> {
  return axios.post(`${machineApiRoot}/timeseries/alerts/_search?${new URLSearchParams(pagingQuery)}`, { machineUuid: twinId });
}

export function getSignalObjective(signalId: string): AxiosPromise<SignalObjective> {
  return axios.get(`${machineApiRoot}/signals/${signalId}/objectives`);
}


export function putSignalObjective(signalId: string, objective: SignalObjective) {
  return axios.put(`${machineApiRoot}/signals/${signalId}/objectives`, objective);
}


export function putAlertMetadata(alertId: string, metadata: AlertMetadata) {
  return axios.put(`/alerts-metadata/${alertId}`, metadata);
}