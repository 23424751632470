


































import {
  DefaultLoadState,
  ErrorState,
  LoadedState,
  LoadingState,
} from "@/store/types";
import Vue, { PropType } from "vue";
import { addComment, getComments } from "../services/comment_service/service";
import Loading from "@/components/Loading.vue";
import { formatDate } from "../util";
import { PropValidator } from "vue/types/options";
import { UserOverview } from '@/services/user_service';
import { GenericComment } from '@/services/comment_service';

export default Vue.extend({
  components: {
    Loading,
  },
  props: {
    comments: { type: Array, required: true } as PropValidator<GenericComment[]>,
  },
  data: () => ({
    loadingState: DefaultLoadState,
    formatDate,
    message: "",
  }),
  methods: {
    formatUser(user: UserOverview) {
        return user.attributes.firstname ? `${user.attributes.firstname} ${user.attributes.lastname}` : user.username;
    },
    sendMessage() {
      this.$emit("sendComment", this.message);
      this.message = "";
      Vue.nextTick(() => {
        this.scrollMessagesDown();
      });
    },
    scrollMessagesDown() {
      const container = this.$refs["messageContainer"] as Element;
      if (container) {
        container.scrollTo({
          top: container.scrollHeight,
          behavior: "smooth",
        });
      }
    },
  },
  mounted() {},
});
