




import { AiSettingHtml } from "@/services/machine_service";
import Vue from "vue";
import { PropValidator } from "vue/types/options";

export default Vue.extend({
  props: {
    settings: { required: true } as PropValidator<AiSettingHtml>,
  },
});
