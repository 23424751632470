































































































import Vue from "vue";
import Loading from "@/components/Loading.vue";
import {
  getSettings,
  postSettings,
  Settings,
} from "../services/settingsService";

import styles from "@/settings.styl.json";

export default Vue.extend({
  components: {
    Loading,
  },
  data() {
    return {
      styles,
      loading: false,
      error: false,
      errorMessage: "",
      overviewData: null,
      settings: null as Settings,
      earliestDate: new Date(),
    };
  },
  methods: {
    async saveChange() {
      this.loading = true;
      postSettings(this.settings).then(
        (response) => {
          this.settings = response;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.error = true;
          this.errorMessage = error;
        }
      );
    },
  },
  mounted() {
    this.loading = true;
    getSettings().then(
      (response) => {
        this.settings = response;
        this.earliestDate = new Date(
          this.settings.synchronization.earliestDate
        );
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.error = true;
        this.errorMessage = error;
      }
    );
  },
});
