import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/views/Login.vue';
import PasswordReset from '@/views/PasswordReset.vue';
import AuthPage from '@/views/AuthPage.vue';
import MachineOverview from '@/views/MachineOverview.vue';
import MachineCreate from '@/views/MachineCreate.vue';
import MachineDetail from '@/views/MachineDetail.vue';
import SignalCreate from '@/views/SignalCreateEdit.vue';
import SettingOverview from '@/views/SettingsOverview.vue';
import DataSourceCreateEdit from '@/views/DataSourceCreateEdit.vue';
import DataSourceOverview from '@/views/DataSourceOverview.vue';
import UserManagementOverview from '@/views/UserManagementOverview.vue';
import UserManagementCreateEdit from '@/views/UserManagementCreateEdit.vue';
import OperationOverview from "@/views/OperationOverview.vue";
import MaterialsOverview from "@/views/MaterialsOverview.vue";
import MaterialCreateEdit from "@/views/MaterialCreateEdit.vue";
import Dashboard from "@/views/Dashboard.vue";
import MachineDashboard from "@/views/MachineDashboard.vue";
import store from '@/store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: MachineDashboard,
      name: 'home',
    },
    {
      path: '/operations',
      component: OperationOverview,
      name: 'operations',
    },
    {
      path: '/materials',
      component: MaterialsOverview,
      name: 'materials',
    },
    {
      path: '/materials/create',
      component: MaterialCreateEdit,
      name: 'materialsCreate',
    },
    {
      path: '/machine',
      component: MachineOverview,
      name: 'machine',
    },
    {
      path: '/machine/create',
      component: MachineCreate,
      name: 'machineCreate',
    },
    {
      path: '/machine/edit/:id',
      component: MachineCreate,
      props: true,
      name: 'machineEdit',
    },
    {
      path: '/machine/:id',
      component: MachineDetail,
      props: true,
      name: 'machineDetail',
    },
    {
      path: '/signal/create',
      component: SignalCreate,
      name: 'signalCreate',
    },
    {
      path: '/signal/:twinId/edit/:id',
      component: SignalCreate,
      props: true,
      name: 'signalEdit',
    },
    {
      path: '/datasources',
      component: DataSourceOverview,
      name: 'dataSources',
    },
    {
      path: '/datasources/create',
      component: DataSourceCreateEdit,
      name: 'dataSourceCreate',
    },
    {
      path: '/datasources/edit/:id',
      component: DataSourceCreateEdit,
      props: true,
      name: 'dataSourceEdit',
    },
    {
      path: '/users',
      component: UserManagementOverview,
      name: 'userOverview',
    },
    {
      path: '/users/create',
      component: UserManagementCreateEdit,
      name: 'userCreate',
    },
    {
      path: '/users/edit/:id',
      component: UserManagementCreateEdit,
      props: true,
      name: 'userEdit',
    },
    {
      path: '/setting',
      component: SettingOverview,
      name: 'setting',
    },
    {
      path: '/',
      component: AuthPage,
      children: [
        {
          path: 'login',
          component: Login,
          name: 'login',
        },
        {
          path: 'reset-password',
          component: PasswordReset,
          name: 'passwordReset',
        },
      ]
    }
  ],
});

router.beforeEach((to: any, from: any, next: any) => {
  const privatePage = !publicPages.includes(to.path);
  if (privatePage && !store.getters['userModule/isLoggedIn']) {
    // TODO: send route to login as next
    return next({ name: 'login' });
  }

  next();
});

export default router;

export const publicPages = [
  '/login',
  '/register',
  '/register-organization',
  '/forgot-password',
  '/reset-password',
];
