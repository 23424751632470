


















import { Material } from "@/services/machine_service";
import { materialLoadingState } from "@/store/machine";
import Vue from "vue";
import Loading from "@/components/Loading.vue";

export default Vue.extend({
  components: {
    Loading,
  },
  computed: {
    materials(): Material[] {
      return materialLoadingState(this.$store).result;
    },
    loading(): boolean {
      return materialLoadingState(this.$store).loading;
    },
    loaded(): boolean {
      return materialLoadingState(this.$store).loaded;
    },
    error(): boolean {
      return materialLoadingState(this.$store).error;
    },
  },
  methods: {},
  mounted() {
    this.$store.dispatch("machineModule/loadMaterials");
  },
});
