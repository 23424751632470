import { getToken } from '../store/user_module/module';
import store from '@/store';


export const roleNames = [
  { id: 1, name: 'global_admin' },
  { id: 2, name: 'system_admin' },
  { id: 3, name: 'user' },
  { id: 4, name: 'expert' },
];


export function userHasRole(roles: string[]) {
  const token = getToken(store)!.jwtToken;
  if (token === null || token === undefined) {
    return false;
  }
  const content = parseJwt(token);
  if (!content.capabilities) {
    return false;
  }
  const userRole = content.capabilities[0].roleId;
  return roles
    // map the names to ids
    .map((name) => roleNames.find((role) => role.name === name).id)
    // check if any of the roles is the user's role
    .reduce((agg, curr) => agg || curr === userRole, false);
}

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
