































































































import Vue from "vue";
import { mapGetters } from "vuex";
import Loading from "@/components/Loading.vue";

export default Vue.extend({
  components: {
    Loading,
  },
  props: {
    signalId: String,
  },
  data: () => ({
    objective: {
      targetValue: 0,
      lowerTargetValue: 0,
      upperTargetValue: 0,
      thresholds: [],
      context: "string",
      isKpi: true,
    },
  }),
  computed: {
    ...mapGetters("aiSettings", ["signalObjective"]),
    isSendButtonActivated(): boolean {
      return true;
    },
    loading(): boolean {
      return this.signalObjective.loading;
    },
    loaded(): boolean {
      return this.signalObjective.loaded;
    },
    error(): boolean {
      return this.signalObjective.error;
    },
  },
  methods: {
    addThreshold() {
      this.objective.thresholds.push({
        name: "",
        type: "gt",
        value: 0,
        hasAlert: false,
        alertType: "warning",
      });
    },
    editThreshold(index: number, key: string, value: string) {
      this.objective.thresholds[index][key] = value;
    },
    removeThreshold(index: number) {
      this.objective.thresholds.splice(index, 1);
    },
    sendData() {
      this.$store.dispatch("aiSettings/updateSignalObjective", {
        id: this.signalId,
        objective: this.objective,
      });
    },
  },
  watch: {
    loaded(isLoaded) {
      if (isLoaded) {
        this.objective = this.signalObjective.result;
      }
    },
  },
  mounted() {
    this.$store.dispatch("aiSettings/loadSignalObjective", this.signalId);
    if (this.loaded) {
      this.objective = this.signalObjective.result;
    }
  },
});
