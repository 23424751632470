import axios from 'axios';
import { apiRoot } from '@/preferences';

export async function getSettings(): Promise<Settings> {
  const resp = axios
    .all([
      axios
        .get(`${apiRoot}/setting/synchronization`),
      axios
        .get(`${apiRoot}/setting/activity`)])
    .then(axios.spread((synchronization, activities) => {
      const settings: Settings = { synchronization: synchronization.data, activities: activities.data };
      return settings;
    }));
  return resp;
}

export function postSettings(settings: Settings): Promise<Settings> {
  const resp = axios
    .all([
      axios
        .post(`${apiRoot}/setting/synchronization`, settings.synchronization),
      axios
        .post(`${apiRoot}/setting/activity`, { operatingStates: settings.activities })])
    .then(axios.spread((synchronization, activities) => {
      const settings: Settings = { synchronization: synchronization.data, activities: activities.data };
      return settings;
    }));
  return resp;
}

export interface SynchronizationSettings {
  interval: {
    month: number,
    day: number,
    hour: number,
    minute: number,
    second: number,
  },
  earliestDate: string,
  duration: string,
  timeFrame: string,
}

export interface ActivitySetting {
  operatingStateSourceId: string;
  description: string;
  shortDescription: string;
  code: string;
  color: string;
  workplaceStateId: string;
  operatingStateClassId: string;
  isRecodable: boolean;
  isSplittable: boolean;
  isAnnotatable: boolean;
  isActive: boolean;
}

export interface Settings {
  synchronization: SynchronizationSettings;
  activities: ActivitySetting[];
}
