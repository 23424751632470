import VueI18n from 'vue-i18n';
import Vue from 'vue';

Vue.use(VueI18n);

const messages = {
  en: {
    to: 'to',
    remove: 'Remove',
    cancel: 'Cancel',
    save: 'Save',
    no: 'No',

    sort_by: 'Sort by',
    table: 'table',
    visual: 'visual',

    // Auth
    eMail: 'E-Mail',
    password: 'Password',
    login: 'Login',
    logout: 'Logout',
    search: 'Search',
    forgot_password: 'Forgot password',
    reset_password: 'Reset password',
    password_reset_sent: 'An e-mail with a password reset link has been sent',

    // Output prediction
    order: 'Order',

    show: 'show',
    group: 'Group',

    outputPredictionTitle: 'Output prediction',
    planned: 'Planned',
    yield: 'Yield',
    scrap: 'Scrap',
    rework: 'Rework',
    probability: 'Probability',
    operation: 'Operation',


    schedule: 'Schedule',
    expected: 'Expected',
    worst: 'Worst Case',
    description: 'Description',
    workplace: 'Workplace',
    distribution: 'Distribution',


    // Create Ticket
    takeAction: 'Take Action',
    createTicket: 'Create Ticket',
    send: 'Send',

    // OEE
    oee: 'OEE',
    availability: 'Availability',
    performance: 'Performance',
    quality: 'Quality',
    date: 'Date',
    today: 'Today',
    trend: 'Trend',
    up: 'Rising',
    down: 'Falling',
    stable: 'Stable',
    all: 'all',
    warning: 'warning',
    alert: 'alert',
    critical: 'critical',
    off: 'off',
    on: 'on',

    // Overview
    overview: 'Overview',
    noDataAvailable: 'No data available',

    // Settings
    settingView: 'Settings Page',
    synchronization: 'Synchronization',
    operationState: 'Operation state',
    user: 'User',
    settings: 'Settings',
    saveChanges: 'Save changes',

    // Settings Synchronization
    interval: 'Synchronization Interval',
    month: 'Month',
    day: 'Day',
    hour: 'Hour',
    minute: 'Minute',
    second: 'Second',
    earliestDate: 'Earliest date',
    earliestDateTooltip: 'This date defines the start date of the first synchronization of the data',
    duration: 'Duration',
    durationTooltip: 'This will define the time duration in the past, the data will be synchronized, after the first synchronization. The duration needs to be in the format of the ISO 8601 duration format ',
    timeFrame: 'Time frame',
    timeFrameTooltip: 'This defines the time frame in that the data will be split for the analysis. The time frame needs to be in the format of the ISO 8601 duration format',

    // Settings Operation states
    operationStateName: 'Operation state name',
    operating_state_class_id: 'State class',
    is_active: 'Is active',

    // Workplace
    next_operation: 'Next Operation',
    in_order: 'Order',
    operation_begin: 'Begin',
    operation_end: 'End',
    production_line: 'Production line',
    operation_schedule: 'Operation Schedule',

    // Machine
    machine: 'Machine',
    signals: 'Signals',
    anomalies_last_week: 'Deviations<br>last week',
    alerts_last_week: 'Alerts last week',
    anomalies_last_day: `Deviations<br>last 24 h`,
    anomalies: 'Deviations',
    timeline: 'Timeline',
    signal: 'Signal',
    type: 'Type',
    display: 'Display',
    machine_overview: 'All machines',
    edit_machine: 'Edit machine',
    no_data_for_chosen_time: 'No data for chosen timeframe.',
    start_date_after_end_date: 'The chosen end date is before the chosen end date.',
    add_machine: 'Add machine',
    machine_id: 'Machine Id',
    machine_name: 'Machine Name',
    machine_type: 'Machine Type',
    machine_description: 'Machine Description',
    search_for_machines: 'Search for machines',
    lines: 'Lines',
    candleSticks: 'Candlesticks',
    set_timeline_to_selection: 'zoom in on timeline selection',
    refresh: 'Refresh',
    zoom: 'Zoom',

    // Signals
    signal_overview: 'Signal Overview',
    sensors: 'Signals',
    search_for_signals: 'Search for signals',
    last_value: 'Last value',
    signal_count_data_points: 'Data points',
    last_signal: 'Last signal:',
    add_signal: 'Add signal',
    edit_signal: 'Edit signal',
    signal_name: 'Signal name',
    signal_type: 'Signal type',
    unit: 'Unit',
    topic: 'Topic',
    signal_name_data_source: 'Signal name in data source',
    signal_description: 'Sensor description',
    signal_off_learning: 'off / learn $percent',
    not_enough_data: 'not enough data',
    ai_running: 'AI running',
    initializing_ai: 'Initializing AI',
    loading_data_in_ai: 'Loading data',
    starting_ai: 'Start AI',
    no_data: 'No data available',
    ai_title: 'AI',
    signal_data: 'Signal data',
    analysis: 'Analysis',
    add_threshold: 'Add Threshold',
    level: 'Level',
    value: 'Value',
    last_signal_date: 'Last data retrieval',

    // DATA Sources
    data_sources: 'Data Sources',
    add_data_source: 'Add Data Source',
    edit_data_source: 'Edit Data Source',
    username: 'Username',
    host: 'Connection string',
    version: 'Version',
    gateway: 'Gateway',

    // User management
    userManagement: 'User Management',
    add_user: 'Add user',
    edit_user: 'Edit user',
    email: 'E-Mail',
    firstname: 'First name',
    lastname: 'Last name',
    role: 'Role',
    unchanged: '(unchanged)',

    // Data Sources
    data_source_overview: 'Data Sources',
    search_data_sources: 'Search Data Sources',
    enter_connection_string: 'Please enter a connection string',
    enter_version: 'Please enter a version',
    enter_gateway: 'Please enter a gateway',
    enter_gateway_tcp: 'Gateway has to start with \'tcp://\' ',



    // Errors
    generic_error: 'An error occurred, try again in a few minutes, if the problem persists, contact your system administrator.',

    // Comments
    comments: 'Comments',
    comment: 'Comment',
    no_comments: 'No comments yet.',
    comment_loading_error: 'An error occured while loading comments, try refreshing the page.',

    // Analysis
    createAnalysis: 'Create Analysis',
    editAnalysis: 'Edit Analysis',
    analysisType: 'Analysis Type',
    create: 'Create',
    name: 'Name',
    status: 'Status',
    lastUpdate: 'Last Update',

    // Analysis - setting translations
    detectorInterval: 'Detector interval',
    windowDelay: 'Window delay',
    monitorInterval: 'Monitor interval',
    numberOfTrees: 'Number of trees',
    sampleSize: 'Sample size',
    outputAfter: 'Output after',
    dimension: 'Dimension',
    lambda: 'Lambda',
    randomSeed: 'Random seed',
    threadPoolSize: 'Thread pool size',
    scoreThreshold: 'Score threshold',
    shingleSize: 'Shingle size',
    deleteAnalysis: 'Delete Analysis',
    confirmDelete: 'Are you sure you want to delete the $name analysis service?',
    delete: 'Delete',
    isStoreSequenceIndexesEnabled: "Enable store sequence indexes",
    isCenterOfMassEnabled: "Enable center of mass",
    isParallelExecutionEnabled: "Enable parallel execution",
    targetAndThresholds: 'Target & Threshold',
    signalData: 'Signal Data',
    alerts: 'Alerts',
    alertType: 'Alert type',
    target: 'Target',
    range: 'Range',
    thresholds: 'Thresholds',
    threshold: 'Threshold',
    addThreshold: 'Add threshold',
    noThresholds: 'There are currently no thresholds configured',
    lowerTargetValue: 'Lower target',
    upperTargetValue: 'Upper target',
    strictUpperBound: 'Strict upper bound',
    strictLowerBound: 'Strict lower bound',
    lowerBound: 'Lower bound',
    upperBound: 'Upper bound',

    startDate: 'Start date',
    endDate: 'End date',
    operationsOverview: 'Operations Overview',
    materialsOverview: 'Materials Overview',
    materialName: 'Material name',
    start: 'Start',
    end: 'End',
    identifier: 'Identifier',
    operationId: 'Operation ID',
    materialCreate: 'Add Material',
    cycleTime: 'Cycle Time',

    machineManagement: 'Machine management',
    machineDetails: 'Machine details',
    sensorManagement: 'Signal management',
    home: 'Home',
    dailyMessages: 'Messages today',
    monitoring: 'Monitoring',
    machineEffectivity: 'Machine effectivity',
    dailyAverage: 'KPI averages today',
    historicalDailyAverage: 'KPI averages over the last 7 days',
    total: 'Total',
    thresholdCrosses: 'Threshold crossings',
    machines: 'Machines',
    sensorValues: 'Sensors',
    anomalieDetectors: 'Deviations',
    deliveryRate: 'Delivery rate',
    outputRate: 'Output rate',
    idleRate: 'Idle rate',
    defaultRate: 'Error rate',
    standstillRate: 'Standstill rate',
    maintenanceRate: 'Maintenance rate',
    dashboard: 'Dashboard',
    criticalAlerts: 'Critical alerts',
    kpis: 'KPIs',
    performanceMessagesLastMonth: 'Performance and messages last month',
    throughput: 'Throughput',
    clockTime: 'Takt time',
    machineOverview: 'Machine overview',
    dailyPerformance: 'Performance last 24 hourse',
    OEE: "OEE",
    time: "Time",
    language: "Language",
    machine_not_connected: "Machine not connected",
    assigned_user: "Assignee",
    no_user_assigned: "No user assigned",
    resolved: "Resolved",
    unresolved: "Unresolved",
    alert_details: 'Alert details',
    resolve: 'Resolve',
    set_unresolved: 'Set unresolved',
    leave_comment: 'Leave a comment'
  },
};

export default new VueI18n({
  locale: 'en',
  messages,
});
