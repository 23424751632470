











import {
  AiSetting,
  AiSettingBoolean,
  AiSettings,
  AiSettingsBooleanResponse,
} from "@/services/machine_service";
import Vue, { PropType } from "vue";
import { mapGetters } from "vuex";
export default Vue.extend({
  props: {
    settings: {
      type: Object as PropType<AiSettingBoolean>,
      required: true,
    },
    aiId: { required: true },
  },
  data: () => ({}),
  computed: {
    ...mapGetters("aiSettings", ["mergedAiSettings"]),
    value: {
      get(): boolean {
        return this.currentSettings?.value;
      },
      set(value: number) {
        if (value !== this.value) {
          this.$store.commit("aiSettings/addAiSettingPatch", {
            id: this.settings.id,
            value: value,
          });
        }
      },
    },
    aiSettings(): AiSettings {
      return this.mergedAiSettings;
    },
    currentSettings(): AiSettingsBooleanResponse {
      if (!this.aiSettings) {
        return undefined;
      }
      const currentSettings = this.aiSettings.find(
        (item: AiSetting) => item.id === this.settings.id
      );
      return currentSettings as AiSettingsBooleanResponse;
    },
    currentValue(): boolean {
      if (this.currentSettings) {
        return this.currentSettings.value;
      }
      return null;
    },
  },
  mounted() {
    // NOTE: the backend requires booleans to be explicitly set, thus we set it to false if no value exists
    if (this.currentSettings === undefined) {
      this.value = false;
    }
  },
});
