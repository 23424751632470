import { GlobalVars } from './types';
import { NavbarSettings, NavbarOption } from '../../models/navbar';
import { Module } from 'vuex';
import { RootState } from '../types';
import { getStoreAccessors } from 'vuex-typescript';


const defaultState: GlobalVars = {
    navbarSettings: undefined,
    breadcrumbs: [],
};

const actions = {};

const mutations = {
    setNavbarSettings(state: GlobalVars, payload: NavbarSettings) {
        state.navbarSettings = payload;
    },
    setBreadCrumbs(state: GlobalVars, payload: NavbarOption[]) {
        state.breadcrumbs = payload;
    },
};

const getters = {
    getNavbarSettings: (state: GlobalVars) => state.navbarSettings,
    getBreadCrumbs: (state: GlobalVars) => state.breadcrumbs,
};

export const globalVars: Module<GlobalVars, RootState> = {
    namespaced: true,
    state: defaultState,
    getters,
    actions,
    mutations,
};



const { commit, read, dispatch } = getStoreAccessors<GlobalVars, RootState>(
    'globalVars',
);

// Getters
export const getNavbarSettings = read(getters.getNavbarSettings);
export const getBreadCrumbs = read(getters.getBreadCrumbs);

// Actions

// Mutations
export const setNavbarSettings = commit(mutations.setNavbarSettings);
export const setBreadCrumbs = commit(mutations.setBreadCrumbs);
