











































































































import { AiMappingAlert, SignalAnomalies } from "@/services/machine_service";
import Vue, { PropType } from "vue";
import {
  DefaultLoadState,
  ErrorState,
  LoadedState,
  LoadingState,
} from "@/store/types";
import { addComment, deleteComment, getComments } from "../services/comment_service/service";
import Loading from "@/components/Loading.vue";
import { formatDate } from "../util";
import { GenericComment } from "@/services/comment_service";
import {
  dispatchLoadUserOverview,
  userOverview,
  userOverviewLoadingState,
} from "@/store/user_module";
import { UserOverview } from "@/services/user_service";
import { addAlertMetadata } from "@/store/ai_settings";

export default Vue.extend({
  components: {
    Loading,
  },
  props: {
    alert: { type: Object as PropType<AiMappingAlert>, required: true },
  },
  data: () => ({
    messsageLoadingState: DefaultLoadState,
    userDialogVisible: false,
    formatDate,
    message: "",
    userSearch: "",
    referenceType: "AlertComment",
  }),
  computed: {
    userSearchLowerCase(): string {
      return this.userSearch.toLocaleLowerCase();
    },
    usersOverview(): UserOverview[] {
      return userOverview(this.$store);
    },
    usersOverviewLoaded(): boolean {
      return userOverviewLoadingState(this.$store).loaded;
    },
    usersOverviewLoading(): boolean {
      return userOverviewLoadingState(this.$store).loading;
    },
    usersOverviewError(): boolean {
      return userOverviewLoadingState(this.$store).error;
    },
    filteredUsers(): UserOverview[] {
      if (this.userSearchLowerCase === "") {
        return this.usersOverview;
      }
      return this.usersOverview.filter((item) =>
        item.attributes.firstname
          ? item.attributes.firstname
              .toLocaleLowerCase()
              .includes(this.userSearchLowerCase)
          : false || item.attributes.lastname
          ? item.attributes.lastname
              .toLocaleLowerCase()
              .includes(this.userSearchLowerCase)
          : false ||
            item.username.toLocaleLowerCase().includes(this.userSearchLowerCase)
      );
    },
    comments(): GenericComment[] {
      return this.messsageLoadingState.result;
    },
    referenceId(): string {
      return this.alert.uuid ;
    },
  },
  methods: {
    formatUser(user: UserOverview) {
        return user.attributes.firstname ? `${user.attributes.firstname} ${user.attributes.lastname}` : user.username;
    },
    sendMessage() {
      const message = this.message;
      this.message = "";
      addComment({
        value: message,
        format: "plain",
        referenceId: this.referenceId,
        referenceType: this.referenceType,
      }).then((result) => {
        this.messsageLoadingState.result.push(result.data);
        Vue.nextTick(() => {
          this.scrollMessagesDown();
        });
      });
    },
    setResolved(resolved: boolean) {
      addAlertMetadata(this.$store, {
        alertId: this.alert.uuid,
        metadata: { assignedUsers: this.alert.assignedUsers, resolved },
      });
    },
    assignUser(user: UserOverview) {
      addAlertMetadata(this.$store, {
        alertId: this.alert.uuid,
        metadata: { resolved: this.alert.resolved, assignedUsers: [user] },
      });
      this.userDialogVisible = false;
    },
    scrollMessagesDown() {
      const container = this.$refs["messageContainer"] as Element;
      if (container) {
        container.scrollTo({
          top: container.scrollHeight,
          behavior: "smooth",
        });
      }
    },
  },
  mounted() {
    this.messsageLoadingState = LoadingState;
    dispatchLoadUserOverview(this.$store, { keepCache: true });
    getComments({
      referenceId: this.referenceId,
      referenceType: this.referenceType,
      //   referenceStart: this.referenceStart,
      //   referenceEnd: this.referenceEnd,
    }).then(
      (response) => {
        this.messsageLoadingState = { ...LoadedState, result: response.data.reverse() };
        Vue.nextTick(() => {
          this.scrollMessagesDown();
        });
      },
      (error) => {
        this.messsageLoadingState = ErrorState;
      }
    );
  },
});
