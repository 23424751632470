<template>
  <svg
    height="100px"
    width="100px"
    :fill="color ? color : '#1A1A1A'"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="-5 -15 100 100"
    enable-background="new -5 -15 100 100"
    xml:space="preserve"
  >
    <polygon
      points="5,75 5,0 0,0 0,80 0.016,80 5,80 89.984,80 89.984,75 "
    ></polygon>
    <polygon
      points="84.998,13.273 88.533,9.738 84.998,6.202 81.462,9.738 82.557,10.833 66.361,27.668 65.164,26.471 63.536,28.099   48.115,20.567 48.527,20.154 44.993,16.619 41.457,20.154 41.541,20.238 16.953,28.338 15.086,26.471 11.55,30.006 15.086,33.542   18.621,30.006 18.537,29.922 43.125,21.822 44.993,23.689 46.62,22.062 62.042,29.594 61.629,30.007 65.164,33.542 68.699,30.007   67.775,29.083 83.972,12.247 "
    ></polygon>
    <path
      d="M84.997,56.347c-1.307,0-2.436,0.718-3.048,1.772l-13.269-3.191c-0.097-1.865-1.626-3.353-3.517-3.353  c-0.792,0-1.516,0.27-2.104,0.709L48.297,41.226c0.142-0.383,0.23-0.793,0.23-1.226c0-1.952-1.582-3.535-3.536-3.535  c-1.952,0-3.535,1.583-3.535,3.535c0,0.362,0.07,0.705,0.172,1.035L17.342,57.181c-0.614-0.513-1.394-0.834-2.256-0.834  c-1.952,0-3.535,1.583-3.535,3.535c0,1.953,1.583,3.535,3.535,3.535c1.953,0,3.536-1.582,3.536-3.535  c0-0.362-0.07-0.705-0.172-1.035l24.287-16.146c0.613,0.513,1.394,0.834,2.256,0.834c0.792,0,1.515-0.27,2.105-0.709l14.763,11.059  c-0.142,0.384-0.23,0.793-0.23,1.226c0,1.953,1.582,3.535,3.535,3.535c1.308,0,2.437-0.718,3.048-1.772l13.269,3.191  c0.097,1.866,1.628,3.353,3.517,3.353c1.953,0,3.535-1.582,3.535-3.535S86.95,56.347,84.997,56.347z"
    ></path>
  </svg>
</template>

<script>
export default {
  props: {
    color: String,
  },
};
</script>