




















import {
  AiSettingLevelValues,
  AiSettingsLevelValuesResponse,
  SignalAis,
} from "@/services/machine_service";
import Vue, { PropType } from "vue";
import { Prop } from "vue/types/options";
import { mapGetters } from "vuex";

export default Vue.extend({
  props: {
    settings: {
      type: Object as PropType<AiSettingLevelValues>,
      required: true,
    },
    currentSettings: {
      type: Object as PropType<AiSettingsLevelValuesResponse>,
    },
  },
  data: () => ({
    value: 0,
  }),
  computed: {
    ...mapGetters("aiSettings", ["signalAiSettings"]),
    aiSettings(): SignalAis[] {
      return this.signalAiSettings;
    },
    currentValueLevels(): any {
      return this.currentValues.map((value, index) => ({
        index: index + 1,
        value,
      }));
    },
    currentValues(): number[] {
      if (this.currentSettings) {
        return this.currentSettings.value.sort((a, b) =>
          this.settings.invert ? b - a : a - b
        );
      }
      return [];
    },
  },
  methods: {
    removeValue(index: number) {
      this.$store.commit("aiSettings/addAiSettingPatch", {
        id: this.settings.id,
        value: this.currentValues.filter((_, i) => i !== index),
      });
    },
    addValue() {
      this.$store.commit("aiSettings/addAiSettingPatch", {
        id: this.settings.id,
        value: [...this.currentValues, this.value],
      });
    },
  },
});
