






import Vue from "vue";
import c3 from "c3";
import moment from "moment";
import { MachineKpi } from "../services/machine_service/types";
import { PropValidator } from "vue/types/options";
import {
  getSignalTimeseriesForTwin,
  SignalTimeseries,
  TimeRangeSeries,
} from "@/services/machine_service";

export default Vue.extend({
  props: {
    signals: Array,
    id: String,
    // NOTE: the name of the KPIs defines what data is shown in the overview chart
    // This is to keep the overview simple, even if a machine has more than two signals
    kpis: Array as PropValidator<MachineKpi[]>,
  },
  data() {
    return {
      chart: undefined,
      loading: false,
      loaded: false,
      error: false,
      sensorDetails: [],
    };
  },
  computed: {
    hasData(): boolean {
      return this.kpiSensors.reduce((agg: boolean, curr: SignalTimeseries) => {
        return curr.timeseries.length > 0 || agg;
      }, false);
    },
    kpiSensors(): SignalTimeseries[] {
      if (this.kpis) {
        return this.sensorDetails.filter(
          (item: SignalTimeseries) =>
            this.kpis.find(
              (kpi: MachineKpi) => kpi.signalId === item.propertyId
            ) !== undefined
        );
      }
      return this.sensorDetails;
    },
  },
  methods: {
    loadData() {
      const startDateIso = moment().subtract(10, "minutes").toISOString();
      const endDateIso = moment().toISOString();
      this.loading = true;

      getSignalTimeseriesForTwin(this.id, startDateIso, endDateIso, 100).then(
        (response) => {
          this.loading = false;
          this.loaded = true;
          this.error = false;
          // Map the date to a date object
          this.sensorDetails = response.data.signals.map((sensor) => ({
            ...sensor,
            timeseries: sensor.timeseries.map((value) => ({
              ...value,
              startDate: moment(value.startDate),
              endDate: moment(value.endDate),
            })),
          }));
          this.setChart();
        },
        () => {
          this.loading = false;
          this.loaded = false;
          this.error = true;
        }
      );
    },
    setChart() {
      if (this.sensorDetails.length === 0) {
        return;
      }
      // Unfortunately c3 has a weird way to set colors: we have to create a dict object with key columname and value color string
      const colors = {} as { [key: string]: string };
      const colorScheme = [
        "rgba(82, 199, 170,0.5)",
        "rgba(170, 82, 199, 0.5)",
      ];
      this.kpiSensors.forEach(
        (item: SignalTimeseries, index) =>
          (colors[item.propertyName] = colorScheme[index])
      );

      this.chart = c3.generate({
        bindto: `#chart-${this.id}`,
        data: {
          //  columns: this.signals.map((item: any) => [item.name, ...item.data]),
          columns: this.kpiSensors.map((item: SignalTimeseries) => [
            item.propertyName,
            ...item.timeseries.map((data: TimeRangeSeries) => data.avg),
          ]),
          colors,
        },
        point: {
          show: false,
        },
        axis: {
          x: {
            show: false,
          },
          y: {
            inner: true,
            tick: {
              format: function (d: number) {
                return d.toFixed(2);
              },
              count: 2,
            },
          },
        },
      });
    },
  },
  watch: {
    kpiSensors(value) {
      this.setChart();
    },
  },
  mounted() {
    this.loadData();
  },
});
