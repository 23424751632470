<template>
  <svg
    height="100px"
    width="100px"
    :fill="color ? color : '#1A1A1A'"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="-5.008 -14.938 100 100"
    enable-background="new -5.008 -14.938 100 100"
    xml:space="preserve"
  >
    <polygon
      points="5,75 5,0 0,0 0,80 0.016,80 5,80 89.984,80 89.984,75 "
    ></polygon>
    <polygon
      points="12.659,10.103 11.659,10.103 11.659,13.571 9.639,13.571 9.639,21.696 11.659,21.696 11.659,25.165 12.659,25.165   12.659,21.696 14.68,21.696 14.68,13.571 12.659,13.571 "
    ></polygon>
    <polygon
      points="22.659,13.103 21.659,13.103 21.659,17.478 19.639,17.478 19.639,29.415 21.659,29.415 21.659,33.79 22.659,33.79   22.659,29.415 24.68,29.415 24.68,17.478 22.659,17.478 "
    ></polygon>
    <polygon
      points="32.659,27.772 31.659,27.772 31.659,32.147 29.639,32.147 29.639,39.082 31.659,39.082 31.659,48.46 32.659,48.46   32.659,39.082 34.68,39.082 34.68,32.147 32.659,32.147 "
    ></polygon>
    <polygon
      points="42.659,23.728 41.659,23.728 41.659,31.665 39.639,31.665 39.639,41.116 41.659,41.116 41.659,44.415   42.659,44.415 42.659,41.116 44.68,41.116 44.68,31.665 42.659,31.665 "
    ></polygon>
    <polygon
      points="52.66,13.447 51.66,13.447 51.66,15.385 49.639,15.385 49.639,30.248 51.66,30.248 51.66,34.134 52.66,34.134   52.66,30.248 54.68,30.248 54.68,15.385 52.66,15.385 "
    ></polygon>
    <polygon
      points="62.66,19.384 61.66,19.384 61.66,24.249 59.639,24.249 59.639,30.248 61.66,30.248 61.66,34.071 62.66,34.071   62.66,30.248 64.68,30.248 64.68,24.249 62.66,24.249 "
    ></polygon>
    <polygon
      points="72.66,20.905 71.66,20.905 71.66,22.843 69.639,22.843 69.639,37.707 71.66,37.707 71.66,41.592 72.66,41.592   72.66,37.707 74.68,37.707 74.68,22.843 72.66,22.843 "
    ></polygon>
    <polygon
      points="84.681,38.438 82.661,38.438 82.661,36.047 81.661,36.047 81.661,38.438 79.64,38.438 79.64,46.91 81.661,46.91   81.661,55.063 82.661,55.063 82.661,46.91 84.681,46.91 "
    ></polygon>
  </svg>
</template>

<script>
export default {
  props: {
    color: String,
  },
};
</script>