import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';

// Register any modules here
import { globalVars } from './global_variables';
import { userModule } from './user_module';
import { aiSettings } from './ai_settings/ai_settings';
import { machineModule } from './machine';

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  modules: {
    userModule,
    globalVars,
    aiSettings,
    machineModule
  },
});
