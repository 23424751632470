import { render, staticRenderFns } from "./CommentSidebar.vue?vue&type=template&id=00cbcb20&scoped=true&lang=pug&"
import script from "./CommentSidebar.vue?vue&type=script&lang=ts&"
export * from "./CommentSidebar.vue?vue&type=script&lang=ts&"
import style0 from "./CommentSidebar.vue?vue&type=style&index=0&id=00cbcb20&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00cbcb20",
  null
  
)

export default component.exports