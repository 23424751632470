import axios, { AxiosPromise } from 'axios';
import { LoginRequest, RegistrationRequest, Profile, UserOverview, UserAttributes } from './types';
import * as preferences from '@/preferences';
import { Token } from '@/models/auth';
import AxiosResponse from 'axios';

export interface TokenResponse {
    refresh: string;
    token: string;
}



export function forgotPassword(email: string): AxiosPromise<any> {
    return axios.post(`${preferences.authApiRoot}/forgot-password`, {
        email,
    });
}

export function resetPassword(
    token: string,
    newPassword: string,
): AxiosPromise<any> {
    return axios.post(`${preferences.authApiRoot}/reset-password`, {
        token,
        newPassword,
    });
}

export function login(
    username: string,
    password: string,
    termsAndConditions: boolean,
): AxiosPromise<TokenResponse> {
    const request: LoginRequest = {
        email: username,
        password,
        accepted: termsAndConditions,
    };
    return axios.post(preferences.authApiRoot + '/login', request);
}

export function createAccount(
    email: string,
    username: string,
    password: string,
    tAndC: boolean,
): AxiosPromise<TokenResponse> {
    const request: RegistrationRequest = {
        email,
        password,
        t_and_c: tAndC,
        username,
        signupKey: preferences.singupKey,
        attributes: {},
    };
    return axios.post(preferences.authApiRoot + '/sign-up', request);
}

export function getUserOverview(): AxiosPromise<UserOverview[]> {
    return axios.get(`${preferences.authApiRoot}/accounts`);
}

export function getUser(id: string): AxiosPromise<UserOverview> {
    return axios.get(`${preferences.authApiRoot}/accounts/${id}`);
}

export function patchUser(id: number | string, userPatch: {email: string, username: string, password: string, attributes: UserAttributes}): AxiosPromise<UserOverview> {
    return axios.patch(`${preferences.authApiRoot}/accounts/${id}`, userPatch);
}

export function removeUser(id: number | string): AxiosPromise<void> {
    return axios.delete(`${preferences.authApiRoot}/accounts/${id}`);
}

export function createUserRole(userId: number | string, roleId: number) {
    return axios.post(`${preferences.authApiRoot}/units/0/accounts/`, { roleId, accountId: userId });
}

export function deleteUserRoles(userId: string | number) {
    return axios.delete(`${preferences.authApiRoot}/units/0/accounts/${userId}`);
}

export function createUser(email: string, username: string, password: string, attributes: UserAttributes): AxiosPromise<UserOverview> {
    return axios.post(`${preferences.authApiRoot}/accounts`, {email , password, username, attributes});
}

export const refreshToken = (token: Token) => {
    const request = {
        refresh: token.refreshToken,
    };
    return axios.post(preferences.authApiRoot + '/renew-token', request);
};

