






















































































































import {
  getSignals,
  getTwins,
  MachineOverview,
  MachineTwin,
  SignalOverview,
} from "@/services/machine_service";
import Vue from "vue";
import ChartOverview from "@/components/ChartOverview.vue";
import { setBreadCrumbs } from "@/store/global_variables";
import { formatTimeDelta } from "@/util/formatting";
import moment from "moment";

export default Vue.extend({
  components: {
    ChartOverview,
  },
  data() {
    return {
      loading: false,
      loaded: false,
      error: false,
      overview: [] as MachineTwin[],
      // NOTE: currently the machine endpoint does not supply signal data,
      // thus we load the data seperately. This can be removed when the machine endpoint supports kpi data.
      signals: [] as SignalOverview[],
      signalsLoading: false,
      formatTimeDelta,
    };
  },
  computed: {
    machineData(): MachineTwin[] {
      return this.overview.map((machine) => {
        const signals = this.signals.filter(
          (signal: SignalOverview) => signal.machine.id === machine.uuid
        );
        return {
          ...machine,
          lastSignalDate:
            signals.length === 0
              ? undefined
              : moment
                  .max(signals.map((signal) => moment(signal.lastSignal)))
                  .toISOString(),
          // NOTE: demo, remove afterwards
          logo: this.getLogo(machine),
          kpis: signals.slice(0, 2).map((signal: SignalOverview) => ({
            signalId: signal.id,
            name: signal.name,
            value:
              signal.lastValue !== null || signal.lastValue === undefined
                ? signal.lastValue.toFixed(1).toString()
                : "-",
            unit: signal.unit,
          })),
        };
      });
    },
  },
  methods: {
    // NOTE: demo, remove afterwards
    getLogo(machine: MachineTwin) {
      switch (machine.name) {
        case "Crusher":
          return "/img/demo/crusher.png";
        case "Filling":
          return "/img/demo/bottle-manufacturing.png";
        case "Heat pump":
          return "/img/demo/heat-pump.svg";
        case "Pasteurizer":
          return "/img/demo/pasteurizer.png";
        case "Conveyor belt":
          return "/img/demo/conveyor-belt.png";
        case "Washer":
          return "/img/demo/fruit-wash.svg";
        case "Mixer":
          return "/img/demo/refinery.svg";
      }
    },
    loadData() {
      this.loading = true;
      // TODO: user store
      getTwins().then(
        (response) => {
          this.overview = response.data;
          this.loading = false;
          this.loaded = true;
          this.error = false;
        },
        (error) => {
          this.error = true;
          this.loading = false;
          this.loaded = false;
          this.overview = [];
        }
      );

      this.signalsLoading = true;
      // NOTE: currently signals api has no machine filter
      getSignals().then(
        (response) => {
          this.signals = response.data.signals;
          this.signalsLoading = false;
        },
        (error) => {}
      );
    },
  },
  created() {
    this.loadData();
    setBreadCrumbs(this.$store, []);
  },
});
