


























































import Vue from "vue";
import Loading from "@/components/Loading.vue";
import {
  createUser,
  createUserRole,
  deleteUserRoles,
  getUser,
  patchUser,
} from "@/services/user_service";
import { roleNames } from "../util/roles";
import { setBreadCrumbs } from "@/store/global_variables";

export default Vue.extend({
  components: {
    Loading,
  },
  props: {
    id: { type: [String, Number], required: false },
  },
  data() {
    return {
      roleNames,
      username: "",
      attributes: {
        firstname: "",
        lastname: "",
        langCode: "EN"
      },
      languages: ['DE', 'EN'],
      password: "",
      email: "",
      role: 3,
      currentRole: 0,
      error: false,
      loading: false,
    };
  },
  computed: {
    isEdit(): boolean {
      return this.id !== undefined;
    },
    isSendButtonActivated(): boolean {
      let isPasswordSet = true;
      if (this.isEdit) {
        isPasswordSet = this.password === "" || this.password.length > 6;
      } else {
        isPasswordSet = this.password !== "" && this.password.length > 6;
      }
      return (
        this.username !== "" &&
        this.attributes.firstname !== "" &&
        this.attributes.lastname !== "" &&
        this.email !== "" &&
        isPasswordSet
      );
    },
  },
  methods: {
    async sendData() {
      if (!this.isSendButtonActivated) {
        return;
      }
      this.loading = true;
      if (this.isEdit) {
        const response = await patchUser(this.id, {
          email: this.email,
          username: this.username,
          password: this.password,
          attributes: this.attributes,
        });
        if (this.role !== this.currentRole) {
          await deleteUserRoles(this.id);
          await createUserRole(this.id, this.role);
        }
        this.loading = false;
        this.$router.push({ name: "userOverview" });
      } else {
        createUser(this.email, this.username, this.password, this.attributes)
          .then((response) => {
            //  Create the user role
            createUserRole(response.data.id, this.role)
              .then((_) => {
                this.loading = false;
                this.$router.push({ name: "userOverview" });
              })
              .catch((_) => {
                (this.loading = false), (this.error = true);
              });
          })
          .catch((_) => {
            (this.loading = false), (this.error = true);
          });
      }
    },
  },
  mounted() {
    if (this.isEdit) {
      this.loading = true;
      getUser(this.id.toString()).then(
        (response) => {
          const user = response.data;
          this.attributes = user.attributes;
          this.email = user.email;
          this.username = user.username;
          this.loading = false;
          this.currentRole = response.data.units[0].roleId;
          this.role = this.currentRole;
        },
        (_) => {
          this.error = true;
        }
      );
    }
    setBreadCrumbs(this.$store, [
      {
        name: "userManagement",
        i18n: true,
        route: { name: "userOverview" },
        icon: "el-icon-user-solid",
      },
      {
        name: this.isEdit ? "edit_user" : "add_user",
        i18n: true,
        route: {
          name: this.isEdit ? "userEdit" : "userCreate",
          params: {
            id: this.id.toString(),
          },
        },
        icon: "el-icon-user-solid",
      },
    ]);
  },
});
