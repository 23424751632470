




























































































































































































































































import Vue from "vue";
import { mapState } from "vuex";
import { NavbarOption, NavbarSettings } from "../models/navbar";
import store from "@/store";
import { publicPages } from "../router";
import CreateTicket from "@/components/CreateTicket.vue";
import { userHasRole } from "../util/roles";
import styles from "@/settings.styl.json";
import { mixin as clickaway } from "vue-clickaway";

/**
 * Navbar routes and breadcrumbs are set through the store: globalVars
 *
 */
export default Vue.extend({
  mixins: [clickaway],

  components: {
    CreateTicket,
  },
  data() {
    return {
      styles,
      userHasRole,
      showUserMenu: false,
      isMenuCollapsed: true,
    };
  },
  computed: {
    ...mapState("globalVars", ["navbarSettings", "breadcrumbs"]),
    isPublicRoute(): boolean {
      return publicPages.includes(this.$route.path);
    },
  },
  methods: {
    logout() {
      store.dispatch("userModule/logout");
    },
    onClickAway() {
      this.isMenuCollapsed = true;
    },
    settings() {
      this.$router.push({
        name: "setting",
      });
    },
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        this.isMenuCollapsed = true;
      }
    },
  },
});
