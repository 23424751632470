import moment, { Moment } from 'moment';
export function toPercentStr(n: number, fixed = 2) {
    // return (n * 100).toFixed(fixed).toString() + '%';
    return Math.floor(n * 100).toString() + '%';
}

export function formatTimeDelta(date: string | Moment) : string {
    if(date !== undefined) {
        const dateMoment = moment(date);
        return dateMoment.fromNow();
    }
    return "-";
}