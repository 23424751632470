export interface RootState { }

export interface SocketListenerState<T> {
    state: LoadState<T>;
    parameters: object;
    subscribers: number;
}

export interface LoadState<T> {
    loading: boolean;
    error: boolean;
    errorMessage: string;
    loaded: boolean;
    result?: T;
}

export const DefaultLoadState: LoadState<any> = {
    loading: false,
    error: false,
    errorMessage: '',
    loaded: false,
    result: null
};

export const LoadingState: LoadState<any> = {
    loading: true,
    error: false,
    errorMessage: '',
    loaded: false,
};

export const ErrorState: LoadState<any> = {
    loading: false,
    error: true,
    errorMessage: '',
    loaded: false,
};

export const LoadedState: LoadState<any> = {
    loading: false,
    error: false,
    errorMessage: '',
    loaded: true,
};
