






import Vue from "vue";
import Header from "./header/Header.vue";
import { setNavbarSettings } from "./store/global_variables";
import store from "@/store";
import { mapGetters, mapState } from "vuex";
import { isLoggedIn } from "./store/user_module/module";

export default Vue.extend({
  name: "app",
  data() {
    return {};
  },
  computed: mapGetters("userModule", ["isLoggedIn"]),
  watch: {
    isLoggedIn(value: boolean) {
      if (value) {
        this.setNavbar();
      } else {
        setNavbarSettings(this.$store, undefined);
      }
    },
  },
  methods: {
    setNavbar() {
      setNavbarSettings(this.$store, {
        title: "Smart Monitoring Cloud",
        options: [
          {
            name: "machine",
            i18n: true,
            route: { name: "machine" },
            icon: "el-icon-s-home",
          },
          {
            name: "userManagement",
            i18n: true,
            route: { name: "userOverview" },
            icon: "el-icon-user-solid",
          },
          {
            name: "data_source_overview",
            i18n: true,
            route: { name: "dataSources" },
            icon: "el-icon-s-tools",
          },
        ],
      });
    },
  },
  mounted() {
    if (this.isLoggedIn) {
      this.setNavbar();
    }
  },
  components: {
    Header,
  },
});
