import { LoadingState, LoadState, DefaultLoadState, RootState, LoadedState, ErrorState } from './types';
import { Operation, Material, MachineTwin, SignalOverview } from '../services/machine_service/types';
import { Module, ActionContext } from 'vuex';
import { getStoreAccessors } from 'vuex-typescript';
import { getOperations, getMaterials, getTwins, getSignals } from '../services/machine_service/service';
import { StatePrediction } from '../services/workplace_prediction_service/types';

interface MachineState {
  operations: LoadState<Operation[]>,
  materials: LoadState<Material[]>,
  signals: LoadState<SignalOverview[]>
  twins: LoadState<MachineTwin[]>
}


const initialState: MachineState = {
  operations: DefaultLoadState,
  materials: DefaultLoadState,
  signals: { ...DefaultLoadState, result: [] },
  twins: DefaultLoadState
}

const actions = {
  loadOperations(context: ActionContext<MachineState, RootState>) {
    context.commit('operationsLoading');
    getOperations().then((result) => {
      context.commit('operationsLoaded', result.data.operations)
    }, error => {
      context.commit('operationsError')
    })
  },
  loadMaterials(context: ActionContext<MachineState, RootState>) {
    context.commit('materialsLoading');
    getMaterials().then((result) => {
      context.commit('materialsLoaded', result.data.materials)
    }, error => {
      context.commit('materialsError')
    })
  },
  loadSignals(context: ActionContext<MachineState, RootState>) {
    context.commit('signalsLoading');
    getSignals().then((result) => {
      context.commit('signalsLoaded', result.data.signals)
    }, error => {
      context.commit('signalsError')
    });
  },
  loadTwins(context: ActionContext<MachineState, RootState>) {
    context.commit('twinsLoading');
    getTwins().then((result) => {
      context.commit('twinsLoaded', result.data)
    },
      error => {
        context.commit('twinsError')
      })
  }
}

const mutations = {
  signalsLoading(state: MachineState) {
    state.signals = { ...LoadingState, result: state.signals.result };
  },
  signalsLoaded(state: MachineState, signals: SignalOverview[]) {
    state.signals = { ...LoadedState, result: signals };
  },
  signalsError(state: MachineState) {
    state.signals = { ...ErrorState, result: state.signals.result };
  },
  twinsLoading(state: MachineState) {
    state.twins = LoadingState;
  },
  twinsLoaded(state: MachineState, result: MachineTwin[]) {
    state.twins = { ...LoadedState, result }
  },
  twinsError(state: MachineState) {
    state.twins = ErrorState;
  },
  operationsLoading(state: MachineState) {
    state.operations = LoadingState;
  },
  operationsLoaded(state: MachineState, result: Operation[]) {
    state.operations = { ...LoadedState, result }
  },
  operationsError(state: MachineState) {
    state.operations = ErrorState;
  },
  materialsLoading(state: MachineState) {
    state.materials = LoadingState
  },
  materialsLoaded(state: MachineState, result: Material[]) {
    state.materials = { ...LoadedState, result }
  },
  materialsError(state: MachineState) {
    state.materials = ErrorState;
  }
}

const getters = {
  operationsLoadingState: (state: MachineState) => state.operations,
  materialsLoadingState: (state: MachineState) => state.materials,
  twinsLoadingState: (state: MachineState) => state.twins,
  signalsLoadingState: (state: MachineState) => state.signals
}

export const machineModule: Module<MachineState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};


const { commit, read, dispatch } = getStoreAccessors<MachineState, RootState>(
  'machineModule',
);

// getters
export const operationsLoadingState = read(getters.operationsLoadingState)
export const materialLoadingState = read(getters.materialsLoadingState)
export const twinsLoadingState = read(getters.twinsLoadingState)