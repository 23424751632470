import { Module, ActionTree, ActionContext } from 'vuex';
import { RootState, DefaultLoadState, LoadingState, LoadedState, ErrorState } from '../types';
import { UserState } from './types';
import { Token } from '@/models/auth';
import { getStoreAccessors } from 'vuex-typescript';
import { log, logErrorResponse, jwtDecode } from '@/util';
import router from '@/router';
import {
  refreshToken,
  login,
  createAccount,
  getUserOverview,
} from '@/services/user_service/service';
import { AxiosPromise } from 'axios';
import { UserOverview } from '../../services/user_service/types';

const rawUser = localStorage.getItem('user');

const defaultstate: UserState = {
  loggedIn: false,
  loginError: false,
  loginErrorMessage: undefined,
  loginLoading: false,

  registrationLoading: false,
  registrationError: false,
  registrationErrorMessage: undefined,

  token: undefined,

  userOverview: {...DefaultLoadState, result: []}
};

// TODO: if no refresh token, check if token is still valid before logging in
const initialState: UserState = rawUser
  ? { ...defaultstate, token: JSON.parse(rawUser), loggedIn: true }
  : defaultstate;

const actions = {
  async loadUserOverview(context: ActionContext<UserState, RootState>, {keepCache} : {keepCache?: boolean}){
    if(keepCache && context.state.userOverview.loaded) {
      return;
    }
    context.commit('userOverviewLoading');
    const response = await getUserOverview().catch((error) => {
      context.commit('userOverviewError');
      throw error;
    });
    context.commit('userOverviewLoaded', response.data);
    return response;
  },
  async login(
    context: ActionContext<UserState, RootState>,
    {
      username,
      password,
      termsAndConditions,
    }: { username: string; password: string; termsAndConditions: boolean },
  ): Promise<AxiosPromise<any>> {
    context.commit('loginRequest', { username });

    log('Sending login request');
    return login(username, password, termsAndConditions).then(
      (response) => {
        log('Login Successful');
        context.commit('loginSuccess', {
          jwtToken: response.data.token,
          refreshToken: response.data.refresh,
        } as Token);
        router.push('/');
        return response;
      },
      (error) => {
        logErrorResponse(error);
        context.commit('loginError', error.response.data.error);
        return error;
      },
    );
  },
  logout(context: ActionContext<UserState, RootState>): void {
    context.commit('logout');
    log('logout');
    // TODO: add info about login to show to user (e.g. session timed out)
    router.push('/login');
  },

  register(
    context: ActionContext<UserState, RootState>,
    {
      username,
      email,
      password,
      tAndC,
    }: {
      email: string;
      username: string;
      password: string;
      tAndC: boolean;
    },
  ): void {
    context.commit('registerRequest');
    log('Sending Registration request');

    createAccount(email, username, password, tAndC).then(
      (response) => {
        log('Registration Successful');
        context.commit('registerSuccess', {
          jwtToken: response.data.token,
          refreshToken: response.data.refresh,
        } as Token);
        router.push('/');
      },
      (error) => {
        logErrorResponse(error);
        context.commit('registerError', error);
      },
    );
  },
};

const mutations = {
  userOverviewLoading(state: UserState){
    state.userOverview = {...LoadingState, result: state.userOverview.result};
  },
  userOverviewLoaded(state: UserState, result: UserOverview[]){
    state.userOverview = {...LoadedState, result: result};
  },
  userOverviewError(state: UserState){
    state.userOverview = {...ErrorState, result: []};
  },
  logout(state: UserState) {
    state.loggedIn = false;
    state.loginError = false;
    state.loginLoading = false;
    state.token = undefined;
    localStorage.removeItem('user');
  },
  loginRequest(state: UserState, username: string) {
    state.loginLoading = true;
    state.loginError = false;
    state.loginLoading = false;
  },
  loginSuccess(state: UserState, token: Token) {
    state.token = token;
    localStorage.setItem('user', JSON.stringify(token));
    state.loginLoading = false;
    state.loggedIn = true;
  },
  refreshToken(state: UserState, token: Token): void {
    state.token = token;
    localStorage.setItem('user', JSON.stringify(token));
    state.loginLoading = false;
    state.loggedIn = true;
  },
  loginError(state: UserState, error: string) {
    state.loginError = true;
    state.loginLoading = false;
    state.loginErrorMessage = error;
  },
  registerRequest(state: UserState) {
    state.registrationError = false;
    state.registrationLoading = true;
  },
  registerSuccess(state: UserState, token: Token) {
    state.token = token;
    localStorage.setItem('user', JSON.stringify(token));
    state.registrationLoading = false;
    state.loggedIn = true;
  },
  registerError(state: UserState, error: string) {
    state.registrationError = true;
    state.registrationLoading = false;
    state.registrationErrorMessage = error;
  },
};

const getters = {
  isLoggedIn: (state: UserState) => state.loggedIn,

  isLoginLoading: (state: UserState) => state.loginLoading,
  hasLoginError: (state: UserState) => state.loginError,
  getLoginError: (state: UserState) => state.loginErrorMessage,

  isRegistrationLoading: (state: UserState) => state.registrationLoading,
  hasRegistrationError: (state: UserState) => state.registrationError,
  getRegistrationError: (state: UserState) => state.registrationErrorMessage,

  getToken: (state: UserState) => state.token,

  getUserOverview: (state: UserState) => state.userOverview.result,
  getUserOverviewLoadingState: (state: UserState) => state.userOverview
};

export const userModule: Module<UserState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};


const { commit, read, dispatch } = getStoreAccessors<UserState, RootState>(
  'userModule',
);


// Getters
export const isLoggedIn = read(getters.isLoggedIn);

export const isLoginLoading = read(getters.isLoginLoading);
export const hasLoginError = read(getters.hasLoginError);
export const getLoginErrorMessage = read(getters.getLoginError);

export const isRegistrationLoading = read(getters.isRegistrationLoading);
export const hasRegistrationError = read(getters.hasRegistrationError);
export const getRegistrationError = read(getters.getRegistrationError);

export const getToken = read(getters.getToken);

export const userOverview = read(getters.getUserOverview);
export const userOverviewLoadingState = read(getters.getUserOverviewLoadingState);

// Actions
export const dispatchLogin = dispatch(actions.login);
export const dispatchRegistration = dispatch(actions.register);
export const dispatchLogout = dispatch(actions.logout);
export const dispatchLoadUserOverview = dispatch(actions.loadUserOverview);

// Mutations
export const commitLoginRequest = commit(mutations.loginRequest);
export const commitTokenRefresh = commit(mutations.refreshToken);
