























































































































































































































































































































































































































































import Vue, { PropType } from "vue";
import Loading from "@/components/Loading.vue";
import { ScaleLinear, scaleLinear, ScaleTime, scaleTime } from "d3-scale";
import { area, curveCardinal, curveLinear, line } from "d3-shape";
import moment, { Moment } from "moment";
import TimelineComments from "@/components/TimelineComments.vue";
import LineChartIcon from "@/components/icons/LineChartIcon.vue";
import CandleStickChartIcon from "@/components/icons/CandleStickChartIcon.vue";
import {
  AnomalyProbability,
  MachineTwin,
  SignalAnomalies,
  SignalObjective,
  SignalTimeseries,
  TimeRangeSeries,
} from "@/services/machine_service";
import { GenericComment } from "@/services/comment_service/types";
import { SensorValues } from "@/services/machine_anomaly_service";

interface Marker {
  startX: number;
  endX: number;
}

export interface ColorMap {
  id: string;
  color: string;
}

/**
 * Display Signal Data
 * The idea is that this chart is not concerned with loading data
 * and can be used with any signals
 */
export default Vue.extend({
  components: {
    Loading,
    TimelineComments,
    LineChartIcon,
    CandleStickChartIcon,
  },
  props: {
    title: { type: String, required: false },
    loading: Boolean,
    sensorOverview: Array as PropType<SignalTimeseries[]>,
    sensorDetails: Array as PropType<SignalTimeseries[]>,
    anomalies: Array as PropType<SignalAnomalies[]>,
    signalChartHeight: Number,
    chartWidth: Number,
    comments: Array as PropType<GenericComment[]>,
    // NOTE: the backend can't return exact dates, thus these might be off
    // If you need the acutal dates from the data, use startDate and endDate instead
    overviewStartDateTime: String,
    overviewEndDateTime: String,
    objectives: { type: Array as PropType<SignalObjective[]>, default: [] },
    // colorMap maps an ID to a color, note that this is used for both, anomalies and signals.
    // If no value is found for a signal/anomaly, the default color will be used
    colorMap: Array as PropType<ColorMap[]>,
  },
  data() {
    return {
      candleChart: false,
      markingTimeline: false,
      markerInitial: 0,
      marker: null as Marker,
      commentMarker: null as Marker,
      showTimelineComments: false,
      trackmouse: false,

      machineDetails: null as MachineTwin,

      yAxisCoordinates: 0,
      xAxisHoverMarker: null,
      debounceChartTimeout: null,
      debounceOverviewTimeout: null,

      draggingOverview: false,
      draggingOverviewPosition: 0,
      draggingOverviewPositionLeft: 0,
      draggingOverviewPositionRight: 0,

      draggingOverviewLeft: false,
      draggingOverviewRight: false,
      chartStart: moment(),
      chartEnd: moment(),
      // dragHandleStart: 0,
      // dragHandleEnd: this.chartWidth,
    };
  },
  computed: {
    dragHandleStart(): number {
      return this.overviewXAxis(this.chartStart);
    },
    dragHandleEnd(): number {
      return this.overviewXAxis(this.chartEnd);
    },
    // Retrieve an array of all buckets that include the current xAxisHoverMarker position
    sensorValuesAtXAxis(): any[] {
      return this.sensorDetails.map((sensor) => {
        const item = this.getIncludingBucket(sensor);
        if (item) {
          return {
            avg: `${item.avg.toFixed(2)} ${sensor.propertyUnit}`,
            min: `${item.min.toFixed(2)} ${sensor.propertyUnit}`,
            max: `${item.max.toFixed(2)} ${sensor.propertyUnit}`,
            endDate: item.endDate,
            startDate: item.startDate,
          };
        }
      });
    },
    hasValuesAtXAxis(): boolean {
      return this.sensorValuesAtXAxis.reduce(
        (aggregate, current) => aggregate || current !== undefined,
        false
      );
    },
    //Get the smallest startDate of the buckets containing the value
    valuesAtXAxisStartDate(): Moment | undefined {
      return this.sensorValuesAtXAxis.reduce((last, current) => {
        if (current === undefined) {
          return last;
        }
        return last === undefined
          ? current.startDate
          : current.startDate.isBefore(last)
          ? current.startDate
          : last;
      }, undefined);
    },
    //Get the largets endDate of the buckets containing the value
    valuesAtXAxisEndDate(): Moment | undefined {
      return this.sensorValuesAtXAxis.reduce((last, current) => {
        if (current === undefined) {
          return last;
        }
        return last === undefined
          ? current.endDate
          : current.endDate.isAfter(last)
          ? current.endDate
          : last;
      }, undefined);
    },
    commentsInChartRange(): GenericComment[] {
      return this.commentsInRange(
        this.xAxis.invert(0),
        this.xAxis.invert(this.chartWidth)
      );
    },
    areaHasData(): boolean {
      return this.sensorDetails.reduce(
        (agg, curr) => curr.timeseries.length > 0 || agg,
        false as boolean
      );
    },
    startDateIsBeforeEndDate(): boolean {
      return moment(this.overviewStartDateTime).isBefore(
        moment(this.overviewEndDateTime)
      );
    },
    isEndTimeInFuture(): boolean {
      return moment(this.overviewEndDateTime).isAfter(moment());
    },
    signalYAxis(): ScaleLinear<number, number>[] {
      return this.sensorDetails.map((sensor) => {
        return scaleLinear()
          .domain([
            0.9 *
              Math.min(
                ...sensor.timeseries.map((item: TimeRangeSeries) => item.avg)
              ),
            1.1 *
              Math.max(
                ...sensor.timeseries.map((item: TimeRangeSeries) => item.avg)
              ),
          ])
          .range([0, this.signalChartHeight]);
      });
    },
    singleYAxisChart(): ScaleLinear<number, number> {
      const minValues = this.sensorDetails.flatMap((sensor) =>
        sensor.timeseries.map((item: TimeRangeSeries) => item.min)
      );
      let min = Math.min(...minValues);

      if (this.objectives.length > 0) {
        min = Math.min(
          min,
          ...this.objectives.map((o) => o.targetValue),
          ...this.objectives.map((o) => o.lowerTargetValue),
          ...this.objectives.map((o) => o.upperTargetValue),
          ...this.objectives.flatMap((o) =>
            o.thresholds.map((item) => item.value)
          )
        );
      }

      const maxValues = this.sensorDetails.flatMap((sensor) =>
        sensor.timeseries.map((item: TimeRangeSeries) => item.max)
      );

      let max = Math.max(...maxValues);

      if (this.objectives.length > 0) {
        max = Math.max(
          max,
          ...this.objectives.map((o) => o.targetValue),
          ...this.objectives.map((o) => o.lowerTargetValue),
          ...this.objectives.map((o) => o.upperTargetValue),
          ...this.objectives.flatMap((o) =>
            o.thresholds.map((item) => item.value)
          )
        );
      }

      const margin = 0.1 * (max - min);

      return scaleLinear()
        .domain([min - margin, max + margin])
        .range([0, this.signalChartHeight]);
    },

    overviewYAxis(): ScaleLinear<number, number> {
      const minValues = this.sensorOverview.flatMap((sensor) =>
        sensor.timeseries.map((item: TimeRangeSeries) => item.min)
      );
      const maxValues = this.sensorOverview.flatMap((sensor) =>
        sensor.timeseries.map((item: TimeRangeSeries) => item.max)
      );

      const min = Math.min(...minValues);
      const max = Math.max(...maxValues);

      return scaleLinear().domain([min, max]).range([0, 50]);
    },

    xAxis(): ScaleTime<any, any> {
      return scaleTime()
        .domain([
          this.overviewXAxis.invert(this.dragHandleStart),
          this.overviewXAxis.invert(this.dragHandleEnd),
        ])
        .range([0, this.chartWidth]);
    },
    yAxisDate(): Moment {
      return moment(this.xAxis.invert(this.yAxisCoordinates));
    },
    overviewXAxis(): ScaleTime<any, any> {
      return scaleTime()
        .domain([this.startDate, this.endDate])
        .range([0, this.chartWidth]);
    },
    // // TODO
    // visibleAnomalies(): SensorValues[] {
    //   return this.anomalies.filter(
    //     (anomaly) =>
    //       this.startDate < anomaly.date && anomaly.date < this.endDate
    //   );
    // },
    anomalyDates(): AnomalyProbability[] {
      return this.anomalies.flatMap((item) =>
        item.timeseries.map((ts) => ({ ...ts, aiMappingId: item.aiMappingId, resolved: item.resolved }))
      );
    },
    detailStartDate(): Moment {
      return moment.min(
        this.chartStart,
        ...this.sensorDetails
          .map((s) =>
            s.timeseries.length > 0
              ? (s.timeseries[0].startDate as Moment)
              : null
          )
          .filter((item) => item !== null)
      );
    },
    detailEndDate(): Moment {
      return moment.max(
        this.chartEnd,
        ...this.sensorDetails
          .map((s) =>
            s.timeseries.length > 0
              ? (s.timeseries[s.timeseries.length - 1].endDate as Moment)
              : null
          )
          .filter((item) => item !== null)
      );
    },
    // NOTE: the backend does not respect the requested start/end dates thus the data might differ from what we requested
    startDate(): Moment {
      return moment.min(
        this.detailStartDate,
        moment(this.overviewStartDateTime)
      );
    },
    endDate(): Moment {
      return moment.max(this.detailEndDate, moment(this.overviewEndDateTime));
    },
    colors(): string[] {
      const len = this.sensorDetails.length;
      return this.sensorDetails.map(
        (_, index) => `hsl(${165 + (360 / len) * index}, 51%, 55%)`
      );
    },
    anomalyColors(): string[] {
      const len = this.sensorDetails.length;
      return this.sensorDetails.map(
        (_, index) => `hsl(${165 + (360 / len) * index}, 61%, 55%)`
      );
    },
    colorsOpaque(): string[] {
      const len = this.sensorDetails.length;
      return this.sensorDetails.map(
        (_, index) => `hsla(${165 + (360 / len) * index}, 51%, 55%, 0.2)`
      );
    },
    overviewPaths(): string[] {
      return (
        this.sensorOverview
          // For lines, we have to cut the lines whenever there are gaps in the data
          .reduce<SignalTimeseries[]>(
            this.cutDataOnPauses,
            <SignalTimeseries[]>[]
          )
          .map((sensor) =>
            line()
              // @ts-ignore NOTE: this is wrongly typed
              .x((d: TimeRangeSeries) => this.overviewXAxis(d.startDate))
              // @ts-ignore NOTE: this is wrongly typed
              .y((d: TimeRangeSeries) => this.overviewYAxis(d.avg))
              // @ts-ignore NOTE: this is wrongly typed
              .curve(curveLinear)(sensor.timeseries)
          )
      );
    },
    signalPaths(): { sensor: any; line: string }[] {
      return (
        this.sensorDetails
          // For lines, we have to cut the lines whenever there are gaps in the data
          .reduce<SignalTimeseries[]>(
            this.cutDataOnPauses,
            <SignalTimeseries[]>[]
          )
          .map((sensor) => ({
            sensor,
            confidenceBand: area()
              // @ts-ignore NOTE: this is wrongly typed
              .x((d: TimeRangeSeries) => this.xAxis(d.startDate))
              // @ts-ignore NOTE: this is wrongly typed
              .y0((d: TimeRangeSeries) => this.singleYAxisChart(d.min))
              // @ts-ignore NOTE: this is wrongly typed
              .y1((d: TimeRangeSeries) => this.singleYAxisChart(d.max))
              .defined(
                // @ts-ignore NOTE: this is wrongly typed
                (item: TimeRangeSeries) =>
                  // @ts-ignore NOTE: this is wrongly typed
                  item.max !== undefined && item.min !== undefined
                // @ts-ignore NOTE: this is wrongly typed
              )(sensor.timeseries),
            line: line()
              // @ts-ignore NOTE: this is wrongly typed
              .x((d: TimeRangeSeries) => this.xAxis(d.startDate))
              // @ts-ignore NOTE: this is wrongly typed
              .y((d: TimeRangeSeries) => this.singleYAxisChart(d.avg))
              // @ts-ignore NOTE: this is wrongly typed
              .defined((item: TimeRangeSeries) => item.avg !== undefined)
              // @ts-ignore NOTE: this is wrongly typed
              .curve(curveLinear)(sensor.timeseries),
          }))
      );
    },
  },
  methods: {
    onWheelChart(event: any) {
      const zoom = event.wheelDelta < 0 ? 1.1 : 0.9;

      const offset = event.offsetX;
      // stretch the distance to start the same as distance to end, this way left/right of the cursor remains constant
      const start = event.offsetX - event.offsetX * zoom;
      const end = event.offsetX + (this.chartWidth - event.offsetX) * zoom;
      // since the overview is the way to set the data, we first have to invert the chart data, then apply the overview axis
      this.setOverview(
        this.overviewXAxis(this.xAxis.invert(start)),
        this.overviewXAxis(this.xAxis.invert(end))
      );
    },
    onOverviewDragStart(event: any) {
      switch (event.target.id) {
        case "drag-handle-left":
          this.startOverviewDragLeft();
          return;
        case "drag-handle-right":
          this.startOverviewDragRight();
          return;
        case "overview-area":
          this.startOverviewDrag(event);
          return;
      }
    },
    onOverviewDragEnd(drag: any) {
      console.log();
      if (
        (this.draggingOverview &&
          this.dragHandleStart === this.draggingOverviewPositionLeft &&
          this.dragHandleEnd === this.draggingOverviewPositionRight) ||
        !this.draggingOverview
      ) {
        // A click has occured and no drag
        this.setOverviewToClick(drag);
      }
      this.stopOverviewDrag();
    },
    onMouseMove(data: any) {
      this.onOverviewDrag(data);
    },
    cutDataOnPauses(agg: SignalTimeseries[], curr: SignalTimeseries) {
      const timeseries = curr.timeseries;
      let currentSeries = [];
      for (const [index, item] of timeseries.entries()) {
        // There can't be a cut at the last element
        if (index === timeseries.length - 1) {
          currentSeries.push({
            ...item,
          });
          agg.push({ ...curr, timeseries: currentSeries });
        } else {
          const nextItem = timeseries[index + 1];
          // If there is no cut start and end must match exactly
          currentSeries.push({
            ...item,
          });
          // TODO: this should not be a fixed time interval
          //
          if (!(nextItem.startDate as Moment).isSame(item.endDate, "minutes")) {
            // If there is a cut, start a new series
            agg.push({
              ...curr,
              timeseries: currentSeries,
            });
            currentSeries = [];
          }
        }
      }
      return agg;
    },
    startTimelineMarker(event: any) {
      this.markingTimeline = true;
      this.markerInitial = event.layerX;
      this.showTimelineComments = false;
      this.marker = {
        startX: event.layerX,
        endX: event.layerX,
      };
    },
    onMouseLeaveChartArea(event: any) {
      this.trackmouse = false;
      if (this.markingTimeline) {
        const bounds = event.srcElement.getBoundingClientRect();
        const xOffset = event.clientX - bounds.left;
        if (xOffset < 0) {
          this.marker.startX = 0;
        } else if (xOffset > this.chartWidth) {
          this.marker.endX = this.chartWidth;
        }
      }
    },
    setChartDetailRange() {
      this.$emit("setDetailChartRange", [
        this.overviewXAxis.invert(this.dragHandleStart).toISOString(),
        this.overviewXAxis.invert(this.dragHandleEnd).toISOString(),
      ]);
    },
    endTimelineMarker() {
      this.markingTimeline = false;
    },
    getIncludingBucket(sensor: SignalTimeseries) {
      // Binary search in sensor for a bucket containing this.xAxisHoverMarker
      const values = sensor.timeseries;
      let rightIndex = values.length - 1;
      let leftIndex = 0;
      let middleIndex = 0;

      while (true) {
        let indexUpdate = Math.floor((leftIndex + rightIndex) / 2);

        middleIndex = indexUpdate;
        if (middleIndex < 0) {
          return undefined;
        }
        if (middleIndex >= values.length - 1) {
          return undefined;
        }
        if (middleIndex === 0) {
          return values[middleIndex];
        }
        if (middleIndex === values.length - 1) {
          return values[middleIndex];
        }

        // If the current x-value is between start of an item and start of the next one
        if (
          // @ts-ignore NOTE: d3 axis can very well handle moment objects
          this.xAxis(values[middleIndex].startDate) <= this.xAxisHoverMarker &&
          // @ts-ignore
          this.xAxisHoverMarker <= this.xAxis(values[middleIndex + 1].startDate)
        ) {
          return values[middleIndex];
        }
        // @ts-ignore
        if (this.xAxis(values[middleIndex].startDate) > this.xAxisHoverMarker) {
          rightIndex = middleIndex - 1;
        } else {
          leftIndex = middleIndex + 1;
        }
      }
    },
    // NOTE: the backend only sends buckets with data,
    // however it also alters the start and end-dates,
    // as this can't be changed, we have to
    fixEmptySensorDetails() {
      if (this.sensorDetails.length === 0) {
        return [];
      }
      const sd = this.sensorDetails;
      for (
        let index = 0;
        index < Math.max(...sd.map((s) => s.timeseries.length));
        index++
      ) {
        const smallestStartDate = moment.min(
          sd
            .map((item) =>
              item.timeseries.length > index
                ? item.timeseries[index].startDate
                : null
            )
            .filter((item) => item !== null) as Moment[]
        );
        const smallestEndDate = moment.min(
          sd
            .map((item) =>
              item.timeseries.length > index
                ? item.timeseries[index].endDate
                : null
            )
            .filter((item) => item !== null) as Moment[]
        );
        sd.forEach((sd) => {
          if (sd.timeseries.length <= index) {
            sd.timeseries.push({
              startDate: smallestStartDate,
              endDate: smallestEndDate,
              min: undefined,
              max: undefined,
              avg: undefined,
            });
          } else if (sd.timeseries[index].startDate > smallestStartDate) {
            sd.timeseries.splice(index, 0, {
              startDate: smallestStartDate,
              endDate: smallestEndDate,
              min: undefined,
              max: undefined,
              avg: undefined,
            });
          }
        });
      }
    },
    formatDate(date: string | Date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    commentsInRange(start: Date, end: Date): GenericComment[] {
      if (this.comments === undefined) {
        return [];
      }
      return this.comments.filter(
        (comment: GenericComment) =>
          moment(comment.referenceStart).isBefore(end) &&
          moment(comment.referenceEnd).isAfter(start)
      );
    },
    sendComment(message: string) {
      this.$emit("sendComment", {
        message,
        referenceStart: this.xAxis.invert(this.marker.startX).toISOString(),
        referenceEnd: this.xAxis.invert(this.marker.endX).toISOString(),
      });
    },
    commentOffset(comment: GenericComment) {
      const start = this.xAxis(moment(comment.referenceStart));
      const end = this.xAxis(moment(comment.referenceEnd));
      const position = start + (end - start) / 2 - 10;
      if (position < 0) {
        return 0;
      }
      if (position > this.chartWidth) {
        return this.chartWidth;
      }
      return position;
    },
    showCommentMarker(comment: GenericComment) {
      this.commentMarker = {
        startX: this.xAxis(moment(comment.referenceStart)),
        endX: this.xAxis(moment(comment.referenceEnd)),
      };
    },
    hideCommentMarker() {
      this.commentMarker = null;
    },
    getColor(sensorId: string) {
      const colorMap = this.colorMap.find((item) => item.id === sensorId);
      if (colorMap) {
        return colorMap.color;
      }
      return "hsl(165, 51%, 55%)";
    },
    getOpaqueColor(sensorId: string) {
      const colorMap = this.colorMap.find((item) => item.id === sensorId);
      const color = colorMap ? colorMap.color : "hsl(165, 51%, 55%)";
      // Add an alpha channel to the color
      return color.replace("hsl", "hsla").replace(")", ", 0.5)");
    },
    getOpaquerColor(sensorId: string){
      const colorMap = this.colorMap.find((item) => item.id === sensorId);
      const color = colorMap ? colorMap.color : "hsl(165, 51%, 55%)";
      // Add an alpha channel to the color
      return color.replace("hsl", "hsla").replace(")", ", 0.2)");
    },
    setTimelineToSelection() {
      const startDate = moment(
        this.overviewXAxis.invert(this.dragHandleStart)
      ).toISOString();
      const endDate = moment(
        this.overviewXAxis.invert(this.dragHandleEnd)
      ).toISOString();

      this.$emit("overviewChartRange", [startDate, endDate]);

      this.chartStart = moment(startDate);
      this.chartEnd = moment(endDate);
      // this.dragHandleStart = 0;
      // this.dragHandleEnd = this.chartWidth;
    },
    setTimelineStartPoint(date: Moment) {
      this.$emit("overviewChartRange", [
        date.toISOString(),
        this.overviewEndDateTime,
      ]);

      this.chartStart = date;
      // this.dragHandleStart = 0;
      // this.dragHandleEnd = this.chartWidth;
    },
    setTimelineEndPoint(date: Moment) {
      this.$emit("overviewChartRange", [
        this.overviewStartDateTime,
        date.toISOString(),
      ]);

      this.chartEnd = date;
      // this.dragHandleStart = 0;
      // this.dragHandleEnd = this.chartWidth;
    },
    startOverviewDrag(event: any) {
      this.draggingOverview = true;
      this.draggingOverviewPosition = event.offsetX;
      this.draggingOverviewPositionLeft = this.dragHandleStart;
      this.draggingOverviewPositionRight = this.dragHandleEnd;
    },
    startOverviewDragLeft() {
      this.draggingOverviewLeft = true;
    },
    stopOverviewDrag() {
      this.draggingOverview = false;
      this.draggingOverviewLeft = false;
      this.draggingOverviewRight = false;
    },
    startOverviewDragRight() {
      this.draggingOverviewRight = true;
    },
    onOverviewDrag(drag: any) {
      const dragX = drag.offsetX;
      if (this.draggingOverviewLeft && dragX < this.dragHandleEnd) {
        this.setOverview(dragX, this.dragHandleEnd);
      } else if (this.draggingOverviewRight && dragX > this.dragHandleStart) {
        this.setOverview(this.dragHandleStart, dragX);
      }
      if (this.draggingOverview) {
        const offset = this.draggingOverviewPosition - dragX;
        if (
          this.draggingOverviewPositionLeft - offset >= 0 &&
          this.draggingOverviewPositionRight - offset <= this.chartWidth
        ) {
          this.setOverview(
            this.draggingOverviewPositionLeft - offset,
            this.draggingOverviewPositionRight - offset
          );
        } else if (this.draggingOverviewPositionLeft - offset < 0) {
          // set to start
          this.setOverview(
            0,
            this.draggingOverviewPositionRight -
              this.draggingOverviewPositionLeft
          );
        } else if (
          this.draggingOverviewPositionRight - offset >
          this.chartWidth
        ) {
          // set to end
          this.setOverview(
            this.chartWidth -
              (this.draggingOverviewPositionRight -
                this.draggingOverviewPositionLeft),
            this.chartWidth
          );
        }
      }
    },
    // This is to zoom to an offset in the details table, which means we have to first calculate the time, and then the offset in the overview table
    zoomToOffset(start: number, end: number) {
      this.removeMarker();
      this.setOverview(
        this.overviewXAxis(this.xAxis.invert(start)),
        this.overviewXAxis(this.xAxis.invert(end))
      );
    },
    removeMarker() {
      this.marker = null;
    },
    setOverview(start: number, end: number) {
      // remove the current marker
      this.removeMarker();
      if (this.debounceChartTimeout) {
        clearTimeout(this.debounceChartTimeout);
      }
      this.chartStart = moment(this.overviewXAxis.invert(start));
      this.chartEnd = moment(this.overviewXAxis.invert(end));
      // this.dragHandleStart = start;
      // this.dragHandleEnd = end;
      this.debounceChartTimeout = setTimeout(this.setChartDetailRange, 100);
    },
    onOverviewMouseLeave(drag: any) {
      // if (this.debounceChartTimeout) {
      //   clearTimeout(this.debounceChartTimeout);
      // }
      // const dragX = drag.offsetX;
      // if (this.draggingOverviewLeft && dragX < this.dragHandleEnd) {
      //   this.chartStart = moment(this.overviewStartDateTime);
      //   // this.dragHandleStart = 0;
      //   this.debounceChartTimeout = setTimeout(this.setChartDetailRange, 100);
      //   this.draggingOverviewLeft = false;
      // } else if (this.draggingOverviewRight && dragX > this.dragHandleStart) {
      //   this.chartEnd = moment(this.overviewEndDateTime);
      //   // this.dragHandleEnd = this.chartWidth;
      //   this.debounceChartTimeout = setTimeout(this.setChartDetailRange, 100);
      //   this.draggingOverviewRight = false;
      // }
    },
    setOverviewToClick(event: any) {
      if (this.debounceChartTimeout) {
        clearTimeout(this.debounceChartTimeout);
      }
      const dragX = event.offsetX;
      if (!this.draggingOverviewLeft && !this.draggingOverviewRight) {
        if (
          Math.abs(dragX - this.dragHandleStart) <
          Math.abs(dragX - this.dragHandleEnd)
        ) {
          this.chartStart = moment(this.overviewXAxis.invert(dragX));
          // this.dragHandleStart = dragX;
        } else {
          // this.dragHandleEnd = dragX;
          this.chartEnd = moment(this.overviewXAxis.invert(dragX));
        }
        this.debounceChartTimeout = setTimeout(this.setChartDetailRange, 100);
        this.draggingOverviewLeft = false;
      }
    },
    // TODO
    getSensorAnomalyAtYAxis(sensor: SignalTimeseries) {
      return false;
      // if(item) {
      //   return item.anomaly;
      // }
    },
    onChartHover(data: any) {
      if (this.markingTimeline) {
        const offset = data.offsetX;
        if (this.markerInitial < offset) {
          this.marker.startX = this.markerInitial;
          this.marker.endX = data.offsetX;
        } else {
          this.marker.startX = data.offsetX;
          this.marker.endX = this.markerInitial;
        }
      } else {
        this.xAxisHoverMarker = data.offsetX;
      }
    },
    onMouseUp() {
      this.stopOverviewDrag();
      this.endTimelineMarker();
    },
  },
  watch: {
    overviewStartDateTime() {
      if (this.debounceOverviewTimeout) {
        clearTimeout(this.debounceOverviewTimeout);
      }
      this.debounceChartTimeout = setTimeout(() => this.$emit("refresh"), 500);
    },
    overviewEndDateTime() {
      if (this.debounceOverviewTimeout) {
        clearTimeout(this.debounceOverviewTimeout);
      }
      this.debounceChartTimeout = setTimeout(() => this.$emit("refresh"), 500);
    },
  },
  mounted() {
    document.body.addEventListener("mouseup", this.onMouseUp);
    this.removeMarker();
    // document.body.addEventListener('click', this.removeMarker);
    if (this.sensorDetails) {
      this.chartStart = this.detailStartDate;
      this.chartEnd = this.detailEndDate;
      this.setChartDetailRange();
    }
  },
  beforeDestroy() {
    document.body.removeEventListener("mouseup", this.onMouseUp);
    // document.body.removeEventListener('click', this.removeMarker);
  },
});
