










































import Vue from "vue";
import Loading from "@/components/Loading.vue";
import { AxiosResponse } from "axios";
import {
  getUserOverview,
  removeUser,
  UserOverview,
} from "@/services/user_service";
import { roleNames } from "@/util/roles";
import { setBreadCrumbs } from "@/store/global_variables";
import { dispatchLoadUserOverview, userOverview, userOverviewLoadingState } from '@/store/user_module';

export default Vue.extend({
  components: {
    Loading,
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    overview(): UserOverview[] {
      return userOverview(this.$store);
    },
    loaded(): boolean {
      return userOverviewLoadingState(this.$store).loaded;
    },
    loading(): boolean {
      return userOverviewLoadingState(this.$store).loading;
    },
    error(): boolean {
      return userOverviewLoadingState(this.$store).error;
    },
    filteredOverview(): UserOverview[] {
      return this.overview.filter(
        (item: UserOverview) =>
          item.username.toLowerCase().includes(this.search.toLowerCase()) ||
          (item.attributes.firstname !== undefined
            ? item.attributes.firstname
                .toLowerCase()
                .includes(this.search.toLowerCase())
            : false) ||
          (item.attributes.lastname !== undefined
            ? item.attributes.lastname
                .toLowerCase()
                .includes(this.search.toLowerCase())
            : false)
      );
    },
  },
  methods: {
    getRoleName(id: number) {
      const role = roleNames.find((item) => item.id === id);
      return role.name;
    },
    loadData() {
      dispatchLoadUserOverview(this.$store, {keepCache: false});
    },
    rowclick(row: UserOverview) {
      this.$router.push({
        name: "userEdit",
        params: { id: row.id.toString() },
      });
    },
    deleteUser(user: UserOverview) {
      removeUser(user.id).then(this.loadData);
    },
  },
  mounted() {
    this.loadData();
    setBreadCrumbs(this.$store, [
      {
        name: "userManagement",
        i18n: true,
        route: { name: "userOverview" },
      },
    ]);
  },
});
