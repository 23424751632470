import { User } from './models/auth';
import store from '@/store';
import { getToken } from './store/user_module';
import moment from 'moment';

export const log = (message: any) => {
    console.log(message);
};

export const logErrorResponse = (error: any) => {
    if (error.response) {
        log(error.response.data);
        log(error.response.status);
        log(error.response.headers);
    } else if (error.request) {
        log(error.request);
    } else {
        log('Error ' + error.message);
    }
    log(error.config);
};

export function getUser(): User | undefined {
    const token = getToken(store);
    if (token === undefined) {
        return undefined;
    }
    return jwtDecode(token.jwtToken);
}

export function isRegisteredUser(username: string) {
    const user = getUser();
    if (user === undefined) {
        return false;
    }
    return user.attributes.username === username;
}

export function formatDate(date: string) {
    return moment(date).format('YYYY/MM/DD HH:mm');
}


export function formatDay(date: string) {
    return moment(date).format('YYYY/MM/DD');
}

const formatInt = (int: number): string => {
    if (int < 10) {
        return `0${int}`;
    }
    return `${int}`;
};

export const formatDuration = (time: number): string => {
    // const seconds = moment.duration(time).seconds();
    const minutes = moment.duration(time).minutes();
    const hours = Math.floor(moment.duration(time).asHours());
    if (hours > 0) {
        return `${time > 0 ? '+' : '-'}${formatInt(hours)}:${formatInt(minutes)}`;
    }
    if (minutes > 0) {
        return `${time > 0 ? '+' : '-'}00:${formatInt(minutes)}`;
    }
    return `00:00`;
    // if (hours > 0) {
    //     return `${formatInt(hours)}:${formatInt(minutes)}:${formatInt(seconds)}`;
    // }
    // if (minutes > 0) {
    //     return `${formatInt(minutes)}:${formatInt(seconds)}`;
    // }
    // return `00:${formatInt(seconds)}`;
};

// TODO: typing
// See https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
export function jwtDecode(str?: string) {
    if (str === undefined) {
        return undefined;
    }
    str = str
        .split('.')[1]
        .replace('-', '+')
        .replace('_', '/');
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return JSON.parse(
        decodeURIComponent(
            atob(str)
                .split('')
                .map((c) => {
                    return (
                        '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                    );
                })
                .join(''),
        ),
    );
}
