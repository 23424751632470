




































import { Operation } from "@/services/machine_service";
import { materialLoadingState, twinsLoadingState } from "@/store/machine";
import Vue from "vue";
import { operationsLoadingState } from "../store/machine";
import { MachineTwin, Material } from "../services/machine_service/types";
import Loading from "@/components/Loading.vue";
import { formatDate } from "@/util";

export default Vue.extend({
  components: {
    Loading,
  },
  data: () => ({}),
  computed: {
    tableData(): (Omit<Operation, "materials"> & { materials: Material[] })[] {
      if (this.operations === null) {
        return [];
      }
      return this.operations.map((item: Operation) => ({
        ...item,
        materials: item.materials.map((materialId: string) =>
          this.materials.find((material) => material.uuid === materialId)
        ),
        machine: this.machines.find(
          (machine: MachineTwin) => item.currentMachine === machine.uuid
        ),
      }));
    },
    materials(): Material[] {
      return materialLoadingState(this.$store).result;
    },
    operations(): Operation[] {
      return operationsLoadingState(this.$store).result;
    },
    machines(): MachineTwin[] {
      return twinsLoadingState(this.$store).result;
    },
    loading(): boolean {
      return (
        materialLoadingState(this.$store).loading ||
        operationsLoadingState(this.$store).loading ||
        twinsLoadingState(this.$store).loading
      );
    },
    loaded(): boolean {
      return (
        materialLoadingState(this.$store).loaded &&
        operationsLoadingState(this.$store).loaded &&
        twinsLoadingState(this.$store).loaded
      );
    },
    error(): boolean {
      return (
        materialLoadingState(this.$store).error ||
        operationsLoadingState(this.$store).error ||
        twinsLoadingState(this.$store).error
      );
    },
  },
  methods: {
    getStartDate(row: Operation) {
      return formatDate(row.startDate);
    },
    getEndDate(row: Operation) {
      return formatDate(row.endDate);
    },
    loadData() {
      this.$store.dispatch("machineModule/loadOperations");
      this.$store.dispatch("machineModule/loadMaterials");
      this.$store.dispatch("machineModule/loadTwins");
    },
  },
  mounted() {
    this.loadData();
  },
});
