






























































































import Vue from "vue";
import { mapGetters } from "vuex";
import Loading from "@/components/Loading.vue";
import { PagingQuery, SignalAnomalies } from "@/services/machine_service";
import { formatDate } from "../util";
import moment from "moment";
import { getMachineAlerts } from "@/store/ai_settings";
import { Table } from "element-ui";
import { LoadState } from "@/store/types";
import AlertDialogContent from "./AlertDialogContent.vue";
import { UserOverview } from '@/services/user_service';

export default Vue.extend({
  components: {
    Loading,
    AlertDialogContent,
  },
  props: {
    machineId: String,
  },
  data: () => ({
    pageSize: 20,
    page: 1,
    totalItems: 0,
    sortProp: "startDate",
    sortOrder: "desc",
    alertDialogItem: null,
  }),
  computed: {
    dialogVisible: {
      get(): Boolean {
        return this.alertDialogItem !== null;
      },
      set(isVisible: Boolean) {
        if (!isVisible) {
          this.alertDialogItem = null;
        }
      },
    },
    anomalies(): SignalAnomalies[] {
      if (!getMachineAlerts(this.$store).result) {
        return [];
      }
      const anomalies = getMachineAlerts(this.$store).result.alerts;
      return anomalies;
    },
    loadingState(): LoadState<any> {
      return getMachineAlerts(this.$store);
    },
  },
  methods: {
    
    formatEndDate(date: any) {
      return formatDate(date.endDate);
    },
    formatStartDate(date: any) {
      return formatDate(date.startDate);
    },
    formatUser(user: UserOverview) {
        return user.attributes.firstname ? `${user.attributes.firstname} ${user.attributes.lastname}` : user.username;
    },
    openAlertDialog(alert: SignalAnomalies) {
      this.alertDialogItem = alert;
    },
    sortBy(event: any) {
      let shouldReload = event.order !== null;
      if (this.sortProp === event.prop && this.sortOrder === event.order) {
        shouldReload = false;
      }
      this.sortProp = event.prop;
      this.sortOrder = event.order;
      if (shouldReload) {
        this.loadData();
      }
    },
    loadData() {
      const queries = {
        pageSize: this.pageSize.toString(),
        currentPage: (this.page - 1).toString(),
      } as PagingQuery;

      if (this.sortOrder !== null) {
        queries.orderBy = this.sortProp;
        queries.orderDirection =
          this.sortOrder === "ascending" ? "asc" : "desc";
      }

      this.$store
        .dispatch("aiSettings/loadMachineAlerts", {
          id: this.machineId,
          queries,
        })
        .then((response) => {
          // NOTE: we have to cache the last number of currentItems since otherwise el-pagination will sync the page to 0
          this.totalItems = response.data.pagination.totalItems;
        });
    },
  },
  watch: {
    page() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
});
